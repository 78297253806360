import { css, html, LitElement } from 'lit';
import { customElement, property } from 'lit/decorators.js';
import { ifDefined } from 'lit/directives/if-defined.js';

export interface ActionInput {
  name: string;
  action?: string;
  disabled?: boolean;
  href?: string;
  target?: string;
  small?: boolean;
  mini?: boolean;
  classes?: string;
  outskirts?: boolean;
  slot?: 'top-right' | 'bottom';
}

/**
 * En kommando link eller "knapp".
 * STATUS OK
 */
@customElement('d-action')
export class DAction extends LitElement {
  static readonly styles = css`
    :host {
      display: inline-block;
      color: var(--themeColor);
      mix-blend-mode: multiply;
      font-size: 11px;
      font-family: var(--small), sans-serif;
      font-weight: 600;
      text-transform: uppercase;
      letter-spacing: 1px;
    }

    :host([theme-page]) {
      color: var(--themeColorDarkerOne);
      mix-blend-mode: normal;
    }

    :host([gray]),
    :host([gray]) a,
    :host([gray]) span {
      color: var(--linkColorGray);
    }

    :host([outskirts]) {
      color: white;
      font-weight: 500;
      mix-blend-mode: normal;
    }

    :host([outskirts][plain]) {
      font-weight: 200;
    }

    span,
    a {
      display: inline-block;
      padding: 6px 8px;
      cursor: pointer;
      text-decoration: none;
      color: inherit;
      outline: none;
    }

    :host([small]) span,
    :host([small]) a {
      font-size: 10px;
    }

    :host([plain]) {
      font-family: var(--mainSans), sans-serif;
      font-weight: 400;
      font-size: 15px;
      text-transform: none;
      letter-spacing: normal;
    }

    :host([mini]) {
      font-family: var(--mainSans), sans-serif;
      font-weight: 400;
      font-size: 13px;
      text-transform: none;
      letter-spacing: normal;
    }

    :host([mini]) span,
    :host([mini]) a {
      padding: 4px 8px;
    }

    :host([disabled]) a,
    :host([disabled]) span,
    :host a[disabled],
    :host span[disabled] {
      color: inherit;
      opacity: 0.5;
      cursor: default;
    }

    @media (hover: hover) {
      a:hover,
      span:hover {
        color: black;
      }
      :host([gray]) a:hover,
      :host([gray]) span:hover {
        color: var(--themeColorDarkerOne);
      }

      :host([delete]) a:hover,
      :host([delete]) span:hover {
        color: red;
      }
      :host([disabled]) a:hover,
      :host([disabled]) span:hover,
      :host a[disabled]:hover,
      :host span[disabled]:hover {
        color: inherit;
        opacity: 0.5;
        cursor: default;
      }
    }
  `;

  @property({ type: Boolean, attribute: true })
  gray = false;
  @property({ type: Boolean, attribute: true })
  small = false;
  @property({ type: Boolean, attribute: true })
  mini = false;
  @property({ type: Boolean, attribute: true })
  disabled = false;
  @property({ type: String })
  href = '';
  @property({ type: Boolean })
  blank = false;
  @property({ type: Boolean, attribute: true })
  outskirts = false;
  @property({ type: Object })
  input: ActionInput | undefined;

  render() {
    if (this.input) {
      if (this.input.href) {
        return html`
          <a
            href="${this.input.href}"
            slot="${this.input.slot ? this.input.slot : ''}"
            target="${ifDefined(this.blank ? '_blank' : undefined)}"
            ?disabled="${this.input.disabled}"
          >
            ${this.input.name}
          </a>
        `;
      }
      if (this.input.action) {
        let action = '';
        if (this.input.action) {
          action = this.input.action;
        }
        let disabled = false;
        if (this.input.disabled) {
          disabled = true;
        }
        return html`
          <span
            ?disabled="${disabled}"
            @click=${() => this.dispatch(disabled, action)}
            slot="${this.input.slot ? this.input.slot : ''}"
          >
            ${this.input.name}
          </span>
        `;
      }
    }
    return html`
      ${this.href === ''
        ? html`
            <span
              @click=${(e) => {
                e.preventDefault();
                e.stopPropagation();
                return this.dispatchEvent(new CustomEvent('click', { composed: true, bubbles: true }));
              }}
              ><slot></slot
            ></span>
          `
        : html` <a href="${this.href}" target="${ifDefined(this.blank ? '_blank' : undefined)}"><slot></slot></a> `}
    `;
  }

  private dispatch(disabled, action) {
    if (!disabled && action) {
      this.dispatchEvent(new CustomEvent('action', { bubbles: true, composed: true, detail: action }));
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-action': DAction;
  }
}
