import Bugsnag from '@bugsnag/js';
import type { ActionCreator, ThunkAction } from '@reduxjs/toolkit';
import { cache, identify, login, RootState } from 'src/store';
import type { LocalDate } from 'src/utilities/local-date.js';
import type {
  CompleteOrganizationViewModel,
  EventOccurrenceViewModel,
  FunctionTaskUpdateMessage,
  MeetingOccurrenceViewModel,
  OrganizationReference,
  PageGroupViewModel,
  ReferenceViewModel,
  SendMeetingNoticeMessageCommand,
  SendMeetingReportMessageCommand,
  SendMessageCommand,
  SendShareVacationCommand,
  StopShareVacationCommand,
} from '../api';
import { BASE_PATH } from '../config.js';
import type {
  DAction,
  DeleteItemAction,
  EntityViewModel,
  LoadedCachedOrganizationAction,
  LoadedErrorAction,
  LoadedEventOccurrencesAction,
  LoadedMeetingOccurrencesAction,
  LoadedOrganizationAction,
  LoadedPageGroupsAction,
  LoadedReferencesAction,
  LoadingOrganizationAction,
  OrganizationState,
  SetTodayAction,
  State,
  UpdateCalendarSingleUserViewAction,
  UpdateCalendarWeekAction,
  UpdateCurrentEditLevelAction,
  UpdateCurrentHelpPageAction,
  UpdateCurrentTutorialIdAction,
  UpdateHelpViewerOpenAction,
  UpdateItemAction,
  UpdatePathAction,
  UpdatePopupOpenAction,
  UpdateSelectedStartTaskAction,
  UpdateTutorialViewerOpenAction,
  UpdateUserAction,
  UpdateUserPropertiesAction,
  User,
} from '../types.js';
import { getOrganization } from 'src/store/selectors/organization.js';
import { Buffer } from 'buffer/index.js';

export type NotifyErrorCallback = (error: Error) => void;
let notifyError: NotifyErrorCallback = () => {
  // empty placeholder
};

export type AlertCallback = (message: string) => void;
let alert: AlertCallback = () => {
  // empty placeholder
};

export function initActions(notifyErrorCallback: NotifyErrorCallback, alertCallback: AlertCallback): void {
  notifyError = notifyErrorCallback;
  alert = alertCallback;
}

class ResponseError extends Error {
  response: any;
}

export type ThunkResult<R> = ThunkAction<R, RootState, unknown, DAction>;

export const updatePath: ActionCreator<UpdatePathAction> = (path: string, queryParams: Record<string, string>) => {
  return {
    type: 'UPDATE_PATH',
    path: path,
    queryParams: queryParams,
  };
};

export const deleteItemAction: ActionCreator<DeleteItemAction> = (message: { path: string }) => {
  return {
    type: 'DELETE_ITEM',
    message: message,
  };
};

export const updateItemAction: ActionCreator<UpdateItemAction> = (message: { path: string; item: EntityViewModel }) => {
  return {
    type: 'UPDATE_ITEM',
    message: message,
  };
};

export const updateUserAction: ActionCreator<UpdateUserAction> = (user?: User) => {
  return {
    type: 'UPDATE_USER',
    user: user,
  };
};

export const updateWeekStart: ActionCreator<UpdateCalendarWeekAction> = (weekStart: string) => {
  return {
    type: 'UPDATE_CALENDAR_WEEK',
    weekStart: weekStart,
  };
};

export const updatePopupOpen: ActionCreator<UpdatePopupOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_POPUP_OPEN',
    popupOpen: value,
  };
};

export const updateCurrentEditLevel: ActionCreator<UpdateCurrentEditLevelAction> = (value: number) => {
  return {
    type: 'UPDATE_CURRENT_EDIT_LEVEL',
    currentEditLevel: value,
  };
};

export const updateHelpViewerOpen: ActionCreator<UpdateHelpViewerOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_HELP_VIEWER_OPEN',
    helpViewerOpen: value,
  };
};

export const updateTutorialViewerOpen: ActionCreator<UpdateTutorialViewerOpenAction> = (value: boolean) => {
  return {
    type: 'UPDATE_TUTORIAL_VIEWER_OPEN',
    tutorialViewerOpen: value,
  };
};
export const updateCurrentTutorialId: ActionCreator<UpdateCurrentTutorialIdAction> = (value: string | undefined) => {
  return {
    type: 'UPDATE_CURRENT_TUTORIAL_ID',
    currentTutorialId: value,
  };
};

export const updateCurrentHelpPage: ActionCreator<UpdateCurrentHelpPageAction> = (currentHelpPage: string) => {
  return {
    type: 'UPDATE_CURRENT_HELP_PAGE',
    currentHelpPage: currentHelpPage,
  };
};

export const updateCalendarSingleUserView: ActionCreator<UpdateCalendarSingleUserViewAction> = (value: boolean) => {
  return {
    type: 'UPDATE_CALENDAR_SINGLE_USER_VIEW',
    singleUserView: value,
  };
};

export const updateSelectedStartTask: ActionCreator<UpdateSelectedStartTaskAction> = (selectedStartTask: string) => {
  return {
    type: 'UPDATE_SELECTED_START_TASK',
    selectedStartTask: selectedStartTask,
  };
};

export const updateUserPropertiesAction: ActionCreator<UpdateUserPropertiesAction> = (
  properties: Record<string, unknown>,
) => {
  return {
    type: 'UPDATE_USER_PROPERTIES',
    properties: properties,
  };
};

export function checkStatus(response: { status: number; statusText?: string }): any {
  if (response.status >= 200 && response.status < 300) {
    return response;
  } else {
    const error = new ResponseError(response.statusText);
    error.response = response;
    throw error;
  }
}

export function onFetchRejected(message: string): (error: Error) => void {
  return (error: Error): void => {
    console.error(message, error);
    notifyError(error);
    alert(message);
  };
}

async function fetchOrganization(id: number, token: string): Promise<any> {
  const response = await fetch(BASE_PATH + '/organizations/' + id + '/fetch', {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  });
  return response.json();
}

async function fetchOrganizationEventOccurrences(id: number, token: string): Promise<any> {
  const response = await fetch(BASE_PATH + '/organizations/' + id + '/eventOccurrences', {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  });
  return response.json();
}

async function fetchOrganizationMeetingOccurrences(id: number, token: string): Promise<any> {
  const response = await fetch(BASE_PATH + '/organizations/' + id + '/meetingOccurrences', {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  });
  return response.json();
}

async function fetchEntity(path: string, token: string): Promise<any> {
  const response = await fetch(BASE_PATH + path, {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  });
  if (response.status >= 200 && response.status < 300) {
    return response.json();
  } else if (response.status === 404) {
    return undefined;
  } else {
    const error = new ResponseError(response.statusText);
    error.response = response;
    throw error;
  }
}

export async function fetchPageGroups(id: number, token: string): Promise<PageGroupViewModel[]> {
  const response = await fetch(BASE_PATH + '/organizations/' + id + '/page-groups', {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  });
  return await response.json();
}

function fetchReferences(id: number, token: string): Promise<any> {
  return fetch(BASE_PATH + '/organizations/' + id + '/references', {
    headers: {
      Authorization: 'Basic ' + token,
      'X-Requested-With': __APP_VERSION__,
    },
  }).then(function (response) {
    return response.json();
  });
}

export const loadedOrganization: ActionCreator<LoadedOrganizationAction> = (
  id: number,
  organization: CompleteOrganizationViewModel,
  user: User,
) => {
  const token = user.token;
  const username = user.username;
  const key = user.key;

  return {
    type: 'LOADED_ORGANIZATION',
    id: id,
    organization: organization,
    token: token,
    username: username,
    key: key,
    user: user,
  };
};

export const loadedCachedOrganization: ActionCreator<LoadedCachedOrganizationAction> = (
  id: number,
  organization: OrganizationState,
  user: {
    key: string;
    username: string;
    token: string;
  },
) => {
  const token = user.token;
  const username = user.username;
  const key = user.key;

  return {
    type: 'LOADED_CACHED_ORGANIZATION',
    id: id,
    organization: organization,
    token: token,
    username: username,
    key: key,
    user: user,
  };
};

export const loadedError: ActionCreator<LoadedErrorAction> = (error: Record<string, unknown>) => {
  console.error(error);
  return {
    type: 'LOADED_ERROR',
    error: error.toString(),
  };
};

export const loadingOrganization: ActionCreator<LoadingOrganizationAction> = () => {
  return {
    type: 'LOADING_ORGANIZATION',
  };
};

export const loadedPageGroups: ActionCreator<LoadedPageGroupsAction> = (pages: PageGroupViewModel[]) => {
  return {
    type: 'LOADED_PAGE_GROUPS',
    pageGroups: pages,
  };
};
export const setToday: ActionCreator<SetTodayAction> = (today: LocalDate) => {
  return {
    type: 'SET_TODAY',
    today: today.toString(),
  };
};

export const loadedReferences: ActionCreator<LoadedReferencesAction> = (references: ReferenceViewModel[]) => {
  return {
    type: 'LOADED_REFERENCES',
    references: references,
  };
};

export const loadedEventOccurrences: ActionCreator<LoadedEventOccurrencesAction> = (
  eventOccurrences: EventOccurrenceViewModel[],
) => {
  return {
    type: 'LOADED_EVENT_OCCURRENCES',
    eventOccurrences: eventOccurrences,
  };
};

export const loadedMeetingOccurrences: ActionCreator<LoadedMeetingOccurrencesAction> = (
  meetingOccurrences: MeetingOccurrenceViewModel[],
) => {
  return {
    type: 'LOADED_MEETING_OCCURRENCES',
    meetingOccurrences: meetingOccurrences,
  };
};

export function loadOrganization(
  id: number,
  user: {
    token: string;
    username: string;
    organizations: OrganizationReference[];
  },
): ThunkResult<Promise<void>> {
  const token = user.token;

  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch, getState) => {
    console.log('start loading organization');
    const state = getState();
    const userOrganization = state.user?.organizations.find((o) => o.id === id);
    if (userOrganization === undefined) {
      const error = new Error('Illegal state (E701), no user organization');
      dispatch(loadedError(error));
    } else if (userOrganization.pending) {
      console.log('Organization pending ' + id);
    } else if (userOrganization.requiresSecureLogin && state.user?.provider === '') {
      console.log('Organization secured ' + id);
    } else if (state.loading) {
      console.log('Organization loading ' + id);
    } else {
      dispatch(loadingOrganization());
      console.log('Loading pages key ' + user.username + '-pages-' + id);
      const p = await cache.get(user.username + '-page-groups-' + id);
      if (p !== null) {
        console.log('cache pages not null');
        dispatch(loadedPageGroups(p));
      }

      console.log('Loading references key ' + user.username + '-references-' + id);
      const r = await cache.get(user.username + '-references-' + id);
      if (r !== null) {
        console.log('cache references not null');
        dispatch(loadedReferences(r));
      }

      const key = user.username + '-organization-' + id;
      console.log('Loading organization key ' + key);
      const o = await cache.get(key);
      if (o !== null) {
        console.log('cache organization not null');
        dispatch(loadedCachedOrganization(id, o, user));
      }

      const pageGroups = await fetchPageGroups(id, token);
      dispatch(loadedPageGroups(pageGroups));

      const references = await fetchReferences(id, token);
      dispatch(loadedReferences(references));

      fetchOrganization(id, token).then(
        (organization) => {
          dispatch(loadedOrganization(id, organization, user));
          fetchOrganizationEventOccurrences(id, token).then((eventOccurrences) => {
            dispatch(loadedEventOccurrences(eventOccurrences));
          });

          fetchOrganizationMeetingOccurrences(id, token).then((meetingOccurrences) => {
            dispatch(loadedMeetingOccurrences(meetingOccurrences));
          });
        },
        (error) => {
          console.error(error);
          return dispatch(loadedError(error));
        },
      );
    }
  };
}

interface MessageType {
  path: string;
  eventType: string;
  item?: unknown;
  hidden?: boolean;
}

export function updateItem(message: MessageType): ThunkResult<Promise<UpdateItemAction | void>> {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.
  return (dispatch, getState): Promise<UpdateItemAction | void> => {
    // Special handling if organization is updated:
    if (message.path.endsWith('/archive')) {
      console.log(message.path);
    }

    if (message.path.endsWith('/archive')) {
      return Promise.resolve();
    } else if (message.eventType === 'delete') {
      dispatch(deleteItemAction(message));
    } else if (message.item || message.hidden) {
      dispatch(updateItemAction(message));
    } else {
      const state = getState();
      const token = state.token ?? '';

      if (token) {
        return fetchEntity(message.path, token).then(function (entity) {
          const m = Object.assign({}, message, { item: entity });

          return dispatch(updateItemAction(m));
        });
      } else {
        return Promise.reject(new Error('No token'));
      }
    }
    return Promise.resolve();
  };
}

export const taskDone =
  (taskUuid: string): ThunkResult<Promise<Response | void>> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    const token = state.token;

    const org = state.organization;

    if (org === undefined) {
      throw new Error('Illegal state (E702), organization not found');
    }
    const functionUuid = org.tasksById[taskUuid].functionUuid;

    return fetch(BASE_PATH + '/organizations/' + id + '/functions/' + functionUuid + '/tasks/' + taskUuid + '/done', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'POST',
      body: '',
    }).catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export const declineTaskTemplateUpdate =
  (taskUuid: string): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    const token = state.token;
    const organization = getOrganization(state);

    if (organization === undefined) {
      throw new Error('Illegal state (E409), expected organization');
    }
    const task = organization.tasksById[taskUuid];
    const functionUuid = task.functionUuid;

    return fetch(
      BASE_PATH + '/organizations/' + id + '/functions/' + functionUuid + '/tasks/' + taskUuid + '/declineTemplate',
      {
        headers: {
          Accept: 'application/json',
          'Content-Type': 'application/json',
          Authorization: 'Basic ' + token,
          'X-Requested-With': __APP_VERSION__,
        },
        method: 'POST',
        body: '',
      },
    ).catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export const declineTemplateUpdate =
  (entityType: string, entityUuid: string): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    if (entityType === 'tasks') {
      // Ignore tasks. Decline using specific function 'declineTaskTemplateUpdate' only
      return Promise.resolve();
    }

    const state = getState();
    const id = state.id;
    const token = state.token;

    return fetch(BASE_PATH + '/organizations/' + id + '/' + entityType + '/' + entityUuid + '/declineTemplate', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'POST',
      body: '',
    }).catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export function urlForItem(entityType: string, item: { uuid: string }): string {
  const mapPrefix: {
    tasks: (i: any) => string;
    contacts: (i: any) => string;
    [key: string]: (i: any) => string;
  } = {
    tasks: function (i: any) {
      return '/functions/' + i.functionUuid;
    },
    contacts: function (i: any) {
      return '/partners/' + i.partnerUuid;
    },
  };

  const defaultPrefix = (): string => '';

  const f = mapPrefix[entityType] || defaultPrefix;

  return f(item) + '/' + entityType + '/' + item.uuid;
}

export const saveItem =
  (type: string, id: string, item: any, parentId = ''): ThunkResult<Promise<void>> =>
  async (dispatch, getState) => {
    console.log('saveItem', type, id, item, parentId);

    const state = getState();
    const organizationId = state.id;
    const token = state.token;

    const organization = state.organization;
    if (organization === undefined) {
      throw new Error('Illegal state (E703), organization not found');
    }
    let originalItem = organization[type + 'ById'][id];
    let url: string;
    if (originalItem) {
      url = urlForItem(type, originalItem);
    } else if (type === 'tasks') {
      url = '/functions/' + item.functionUuid + '/tasks/' + id;
    } else {
      url = '/' + type + '/' + id;

      const m: {
        tasks: { [p: string]: any };
        contacts: { [p: string]: any };
        [key: string]: { [p: string]: any };
      } = {
        tasks: organization.functionsById,
        contacts: organization.partnersById,
      };

      const parentItem = m[type] && m[type][parentId];

      if (parentItem) {
        const p: {
          tasks: string;
          contacts: string;
          [key: string]: string;
        } = {
          tasks: 'functions',
          contacts: 'partners',
        };

        url = urlForItem(p[type], parentItem) + url;

        const defaults: {
          tasks: { function: string };
          contacts: Record<string, unknown>;
          [key: string]: Record<string, unknown>;
        } = {
          tasks: {
            function: item.functionUuid,
          },
          contacts: {},
        };

        originalItem = defaults[type];
      }
    }

    const updatedItem = Object.assign({}, originalItem, item, {
      uuid: id,
      saving: true,
    });

    const message = {
      path: '/organizations/' + organizationId + url,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    if (type === 'computers' && item.unitType === 'printer') {
      item = {
        unitType: item.unitType,
        type: 'UNDEFINED',
        name: item.name,
        networkUuid: item.networkUuid,
        serviceProvider: '',
        contracts: [],
        systemUpdateOperatorType: 'UNDEFINED',
        systemUpdateOperator: '',
        antiVirusOperatorType: 'UNDEFINED',
        antiVirusOperator: '',
        antiVirusRequirements: [],
        connectionType: 'OTHER',
        location: item.location,
        elevated: false,
        locked: false,
        restrictedPhysicalAccess: false,
        displayPositionSecure: false,
        mobileMedicalDataRequirements: [],
        notes: item.notes,
        printerPositionSecure: item.printerPositionSecure,
        isConfirmedEntity: item.isConfirmedEntity,
      };
    }

    return fetch(BASE_PATH + '/organizations/' + organizationId + url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then(function (res) {
        console.log(res);
        setTimeout(function () {
          const s = getState();
          const organization1 = s.organization;
          if (organization1 === undefined) {
            return;
          }
          const o = organization1[type + 'ById'][id];
          if (o) {
            console.log('After saving ' + type + ' ' + id + ': ' + o.saving);
            if (o.saving) {
              // Trigger GET item if message not received
              dispatch(
                updateItem({
                  path: '/organizations/' + organizationId + url,
                  eventType: 'update',
                }),
              );
            }
          }
        }, 500);
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

export const createRiskAssessment = (uuid: string, name: string): ThunkResult<Promise<void>> => {
  const item = {
    description: '',
    responsibleBy: '',
    probability: 0,
    consequence: 0,
    riskDescription: '',
    measures: '',
    assessmentDate: '',
    violatesInformationSecurityRequirements: false,
    violatesIntegrityRequirement: false,
    violatesConfidentialityRequirement: false,
    violatesNonRepudiationRequirement: false,
    violatesAvailabilityRequirement: false,
    determinedBy: '',
    name: name,
    pages: [279, 65],
  };

  return saveItem('riskAssessments', uuid, item);
};

export const createContract = (uuid: string, name: string): ThunkResult<Promise<void>> => {
  const item = {
    status: '',
    profession: '',
    sideCode: '',
    validFromDate: '',
    templateId: '',
    content: '',
    templateEmployeeId: '',
    category: '',
    partners: [],
    name: name,
    validToDate: '',
    documentLocation: '',
    affiliation: '',
    required: '',
    employees: [],
    accessControl: [],
    classification: 'NONE',
    pages: [65],
  };

  return saveItem('contracts', uuid, item);
};

export const createPartner = (uuid: string, name: string): ThunkResult<Promise<void>> => {
  const item = {
    remoteAccess: false,
    phone: '',
    address: '',
    name: name,
    physicalAccess: false,
    pageAccess: false,
    industry: '',
    notes: '',
    pages: [65],
    email: '',
    url: '',
  };

  return saveItem('partners', uuid, item);
};

export const createAsset = (uuid: string, name: string, number: string): ThunkResult<Promise<void>> => {
  const item = {
    model: '',
    location: '',
    maintenanceRequired: false,
    number: number,
    competency: '',
    userManual: '',
    storesSensitiveInformation: false,
    accessory: '',
    manufacturer: '',
    name: name,
    supplier: '',
    assetVerified: false,
    discardedYear: '',
    documentationVerified: false,
    requiresElectricity: false,
    acquiredYear: '',
    owner: '',
    maintenanceInterval: '',
    documented: false,
    category: 'Ingen',
    maintenance: '',
    complianceDeclaration: '',
  };

  return saveItem('assets', uuid, item);
};

/**
 * Gets the first name, technically gets all words leading up to the last
 * Example: "Blake Robertson" --> "Blake"
 * Example: "Blake Andrew Robertson" --> "Blake Andrew"
 * Example: "Blake" --> "Blake"
 * @param str
 * @returns {*}
 */
const getFirstName = function (str: string): string {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return arr[0];
  }
  return arr.slice(0, -1).join(' '); // returns "Paul Steve"
};

/**
 * Gets the last name (e.g. the last word in the supplied string)
 * Example: "Blake Robertson" --> "Robertson"
 * Example: "Blake Andrew Robertson" --> "Robertson"
 * Example: "Blake" --> "<None>"
 * @param str
 * @param {string} [ifNone] optional default value if there is not last name, defaults to "<None>"
 * @returns {string}
 */
const getLastName = function (str: string, ifNone: string): string {
  const arr = str.split(' ');
  if (arr.length === 1) {
    return ifNone || '<None>';
  }
  return arr.slice(-1).join(' ');
};

export const createEmployee = (uuid: string, name: string): ThunkResult<Promise<void>> => {
  const item = {
    lastName: getLastName(name, ''),
    phone: '',
    associationType: '',
    profession: '',
    accessLevel: 'NONE',
    secondaryPhone: '',
    hprNumber: '',
    address: '',
    email: '',
    herNumber: '',
    notes: '',
    firstName: getFirstName(name),
    group: false,
    expertise: '',
    status: 'ACTIVE',
    gender: '',
  };

  return saveItem('employees', uuid, item);
};

export const deleteItem =
  (entityType: string, uuid: string): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    const token = state.token;

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E704), organization not found');
    }
    const uuidNoInstance = uuid.split(':')[0];
    const item = organization1[entityType + 'ById'][uuidNoInstance];

    const newItem = { ...item, deleted: true };
    const url = urlForItem(entityType, item);

    dispatch({
      type: 'UPDATE_ITEM',
      message: {
        path: '/organizations/' + id + url,
        eventType: 'update',
        item: newItem,
      },
    });

    const endpoint = BASE_PATH + '/organizations/' + id + url;

    return fetch(endpoint, {
      method: 'DELETE',
      headers: {
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
    })
      .then(checkStatus)
      .catch(onFetchRejected('Feil ved sletting. Prøv igjen.'));
  };

function uploadEndpoint(uuid: string, organization1: OrganizationState, entityType: string, id: number, file: File) {
  const { url, endpoint } = attachmentEndpoint(uuid, organization1, entityType, id);
  return { url, endpoint: endpoint + '/' + encodeURIComponent(file.name) };
}

function attachmentEndpoint(uuid: string, organization1: OrganizationState, entityType: string, id: number) {
  if (entityType === 'eventOccurrences' || entityType === 'meetingOccurrences') {
    const url = '/' + entityType + '/' + uuid;

    const endpoint = BASE_PATH + '/organizations/' + id + url + '/attachments';
    return { url, endpoint };
  } else {
    const uuidNoInstance = uuid.split(':')[0];
    const item = organization1[entityType + 'ById'][uuidNoInstance];

    const url = urlForItem(entityType, item);

    const endpoint = BASE_PATH + '/organizations/' + id + url + '/attachments';
    return { url, endpoint };
  }
}

export const uploadFile =
  (entityType: string, uuid: string, file: File): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }
    const token = state.token;

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E705), organization not found');
    }
    const { url, endpoint } = uploadEndpoint(uuid, organization1, entityType, id, file);

    return fetch(endpoint, {
      method: 'PUT',
      headers: {
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
        'Content-Type': 'application/octet-stream',
      },
      body: file,
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem({
            path: '/organizations/' + id + url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved opplasting. Prøv igjen.'));
  };

export const renameAttachment =
  (entityType: string, uuid: string, attachmentId: string, name: string): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }

    const token = state.token;

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E706), organization not found');
    }

    const e = attachmentEndpoint(uuid, organization1, entityType, id);

    const endpoint = e.endpoint + '/' + attachmentId + '/properties';

    const p = {
      name: name,
    };
    return fetch(endpoint, {
      method: 'PUT',
      headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
        'Content-Type': 'application/json',
      },
      body: JSON.stringify(p),
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem({
            path: '/organizations/' + id + e.url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved endring av vedlegg. Prøv igjen.'));
  };

export const deleteAttachment =
  (entityType: string, uuid: string, attachmentId: string): ThunkResult<void> =>
  (dispatch, getState): Promise<Response | void> => {
    const state = getState();
    const id = state.id;
    if (id === null) {
      throw new Error('Illegal state (E706), id not found');
    }
    const token = state.token;

    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E707), organization not found');
    }

    const e = attachmentEndpoint(uuid, organization1, entityType, id);

    const endpoint = e.endpoint + '/' + attachmentId;

    return fetch(endpoint, {
      method: 'DELETE',
      headers: {
        Accept: 'application/json',
        Authorization: 'Basic ' + token,
        'Content-Type': 'application/json',
        'X-Requested-With': __APP_VERSION__,
      },
    })
      .then(checkStatus)
      .then(() => {
        dispatch(
          updateItem({
            path: '/organizations/' + id + e.url,
            eventType: 'update',
          }),
        );
      })
      .catch(onFetchRejected('Feil ved sletting av vedlegg. Prøv igjen.'));
  };

export const updateOrganization =
  (item: any): ThunkResult<void> =>
  (dispatch, getState): void => {
    const state = getState();
    const id = state.id;
    const token = state.token;

    fetch(BASE_PATH + '/organizations/' + id, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then((res) => {
        console.log(res);
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

/**
 * Updates the additional properties of the organization.
 *
 * @param p Object with keys for each additional property to set. Other additional
 *        properties are not affected. Set value to null for a key to delete the key.
 * @returns {Function}
 */
export const updateOrganizationProperties = (p: any): ThunkResult<Promise<void>> => {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return new Promise<void>((resolve) => resolve());
    }

    const id = state.id;
    const token = state.token;

    return fetch(BASE_PATH + '/organizations/' + id + '/properties', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(p),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Kan ikke oppdatere.'));
  };
};

/**
 * Updates the additional properties of the current user.
 *
 * User properties are used for properties that are relevant only to the current user. This includes per organization
 * properties. To set
 *
 * @param p Object with keys for each additional property to set. Other additional
 *        properties are not affected. Set value to null for a key to delete the key.
 * @returns {Function}
 */
export const updateUserProperties = (p: Record<string, unknown>): ThunkResult<Promise<void>> => {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return new Promise<void>((resolve) => resolve());
    }

    dispatch(updateUserPropertiesAction(p));

    const token = state.token;

    return fetch(BASE_PATH + '/accounts/properties', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(p),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Kan ikke oppdatere.'));
  };
};

/**
 * Updates the additional properties of the current user.
 *
 * User properties are used for properties that are relevant only to the current user. This includes per organization
 * properties. To set
 *
 * @param p Object with keys for each additional property to set. Other additional
 *        properties are not affected. Set value to null for a key to delete the key.
 * @param organizationId
 * @returns {Function}
 */
export const updateUserPropertiesForOrganization = (
  p: { [key: string]: any },
  organizationId: number,
): ThunkResult<Promise<void>> => {
  const prefixKeys = (m: { [key: string]: any }, prefix: string): { [key: string]: any } => {
    const keys = Object.keys(m);
    const result: { [key: string]: any } = {};

    keys.forEach(function (k) {
      const key = prefix + k;
      result[key] = m[k];
    });

    return result;
  };

  const prefixedProperties = prefixKeys(p, organizationId + '_');

  return updateUserProperties(prefixedProperties);
};

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendMessage = (p: SendMessageCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(JSON.stringify(p), ':send-message');
};

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendMeetingNoticeMessage = (p: SendMeetingNoticeMessageCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(JSON.stringify(p), ':send-meeting-notice-message');
};

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendMeetingReportMessage = (p: SendMeetingReportMessageCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(JSON.stringify(p), ':send-meeting-report-message');
};

export const saveTask =
  (id: string, item: FunctionTaskUpdateMessage): ThunkResult<void> =>
  (dispatch, getState): Promise<void> => {
    console.log('saveTask', id, item);

    const state = getState();
    const organizationId = state.id;
    const token = state.token;
    const organization1 = state.organization;
    if (organization1 === undefined) {
      throw new Error('Illegal state (E708), organization not found');
    }

    const originalItem = organization1.tasksById[id];
    const url: string = '/functions/' + item.functionUuid + '/tasks/' + id;

    const updatedItem = Object.assign({}, originalItem, item, {
      uuid: id,
      saving: true,
    });

    const message = {
      path: '/organizations/' + organizationId + url,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    return fetch(BASE_PATH + '/organizations/' + organizationId + url, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(item),
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Der var et problem ved lagring. Vennligst prøv igjen.'));
  };

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const sendShareVacation = (p: SendShareVacationCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(JSON.stringify(p), ':send-share-vacation');
};

function sendOrganizationMessage(body: string, target: string): ThunkResult<Promise<void>> {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return (dispatch, getState): Promise<void> => {
    const state = getState();

    if (!state.organization) {
      return new Promise<void>((resolve) => resolve());
    }

    const id = state.id;
    const token = state.token;

    return fetch(BASE_PATH + '/organizations/' + id + '/' + target, {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'POST',
      body: body,
    })
      .then(function (res) {
        console.log(res);
      })
      .catch(onFetchRejected('Kan ikke sende melding.'));
  };
}

/**
 * Send a message to employees of an organization.
 *
 * @param p The send message command object.
 * @returns {Function}
 */
export const stopShareVacation = (p: StopShareVacationCommand): ThunkResult<Promise<void>> => {
  return sendOrganizationMessage(JSON.stringify(p), ':stop-share-vacation');
};

export const loginUserAction = (
  username: string,
  password: string,
): ThunkAction<Promise<boolean>, State, unknown, DAction> => {
  // Invert control!
  // Return a function that accepts `dispatch` so we can dispatch later.
  // Thunk middleware knows how to turn thunk async actions into actions.

  return async (dispatch): Promise<boolean> => {
    try {
      const token = Buffer.from(username + ':' + password).toString('base64');
      console.log('Logging in');
      const response = await login(username, token);
      console.log('Logging in response', response);
      const organizations = response.organizations;

      Bugsnag.setUser(undefined, username);

      identify(username);

      try {
        localStorage.setItem('dabih-username', username);
        localStorage.setItem('dabih-token', token);
        localStorage.setItem('dabih-issued', new Date().toISOString());
      } catch (e: any) {
        console.error(e);
        Bugsnag.notify(e);
      }

      const user = {
        ...response,
        username: username,
        token: token,
        organizations: organizations,
        key: response.key,
        ablyTokenRequest: response.ablyTokenRequest,
      };

      console.log(user);

      dispatch(updateUserAction(user));

      // .catch((e) => console.error(e));

      return true;
    } catch (e) {
      console.error(e);
      return false;
    }
  };
};

export function updateEntityAdditionalProperties(
  entityType: string,
  uuid: string,
  properties: Record<string, unknown>,
): ThunkResult<Promise<void>> {
  return function (dispatch, getState): Promise<void> {
    const state = getState();

    if (!state.organization) {
      return Promise.resolve();
    }

    const id = state.id;
    const token = state.token;
    const originalItem = state.organization[entityType + 'ById'][uuid];
    const updatedItem = Object.assign({}, originalItem, properties, {
      saving: true,
    });
    const message = {
      path: '/organizations/' + id + '/' + entityType + '/' + uuid,
      eventType: 'update',
      item: updatedItem,
    };

    dispatch({
      type: 'UPDATE_ITEM',
      message: message,
    });

    return fetch(BASE_PATH + '/organizations/' + id + '/' + entityType + '/' + uuid + '/properties', {
      headers: {
        Accept: 'application/json',
        'Content-Type': 'application/json',
        Authorization: 'Basic ' + token,
        'X-Requested-With': __APP_VERSION__,
      },
      method: 'PUT',
      body: JSON.stringify(properties),
    })
      .then(function (res) {
        console.log(res);
        setTimeout(function () {
          const s = getState();
          const organization = s.organization;
          if (organization !== undefined) {
            const o = organization[entityType + 'ById'][uuid];
            if (o) {
              console.log('After saving properties ' + entityType + ': ' + uuid + ': ' + o.saving);
              if (o.saving) {
                // Trigger GET item if message not received
                dispatch(
                  updateItem({
                    path: '/organizations/' + id + '/' + entityType + '/' + uuid,
                    eventType: 'update',
                  }),
                );
              }
            }
          }
        }, 500);
      })
      .catch(function (res) {
        console.log(res);
      });
  };
}
