/**
 * Temporary API methods not connected to the redux store. Replaces all iron-ajax use in dabih
 *
 *
 */

import {
  AccountsApi,
  AccountStateUpdateMessage,
  AccountUpdateMessage,
  AccountViewModel,
  AssetsApi,
  AttachmentViewModel,
  ComputersApi,
  Configuration,
  ConstitutionalDocumentsApi,
  ContractsApi,
  CreateUserRequest,
  CreateUserResponse,
  DocumentsApi,
  EmployeesApi,
  EventOccurrencesApi,
  FeedbackMessage,
  FunctionsApi,
  GuidelinesApi,
  IssuesApi,
  LinkAuthResult,
  LinkAuthUpdateMessage,
  MeetingOccurrencesApi,
  MeetingsApi,
  MessageViewModel,
  OrganizationApi,
  OrganizationSettingsUpdateMessage,
  PartnersApi,
  ReportsApi,
  RestoreCommand,
  RevisionViewModel,
  RiskAssessmentsApi,
  SaveDraftCommand,
  SearchResultHit,
  SubstancesApi,
  UnlinkAuthMessage,
  UpdateSecureLoginRequest,
} from './api';
import pDebounce from 'p-debounce';
import { BASE_PATH } from './config.js';
import { Buffer } from 'buffer/index.js';

export function decodeToken(token: string): string {
  return Buffer.from(token, 'base64').toString();
}

export class ApiClient {
  get accounts(): AccountsApi {
    return new AccountsApi(this.config);
  }
  get organization(): OrganizationApi {
    return new OrganizationApi(this.config);
  }
  get eventOccurrences(): EventOccurrencesApi {
    return new EventOccurrencesApi(this.config);
  }

  get meetingOccurrences(): MeetingOccurrencesApi {
    return new MeetingOccurrencesApi(this.config);
  }

  get functions(): FunctionsApi {
    return new FunctionsApi(this.config);
  }

  get documents(): DocumentsApi {
    return new DocumentsApi(this.config);
  }

  get meetings(): MeetingsApi {
    return new MeetingsApi(this.config);
  }

  get guidelines(): GuidelinesApi {
    return new GuidelinesApi(this.config);
  }

  get assets(): AssetsApi {
    return new AssetsApi(this.config);
  }
  get reports(): ReportsApi {
    return new ReportsApi(this.config);
  }
  get riskAssessments(): RiskAssessmentsApi {
    return new RiskAssessmentsApi(this.config);
  }

  get partners(): PartnersApi {
    return new PartnersApi(this.config);
  }

  get contracts(): ContractsApi {
    return new ContractsApi(this.config);
  }
  get constitutionalDocuments(): ConstitutionalDocumentsApi {
    return new ConstitutionalDocumentsApi(this.config);
  }
  get employees(): EmployeesApi {
    return new EmployeesApi(this.config);
  }

  private get config(): Configuration {
    const token = localStorage.getItem('dabih-token');
    let username: string | undefined = undefined;
    let password: string | undefined = undefined;
    if (token) {
      const strings = decodeToken(token).split(':');
      username = strings[0];
      password = strings[1];
    }

    return new Configuration({
      basePath: '/api',
      username: username,
      password: password,
      fetchApi: fetch,
      headers: {
        'X-Requested-With': __APP_VERSION__,
      },
    });
  }
}

export function createClient(): ApiClient {
  return new ApiClient();
}

export async function login(username: string, token: string): Promise<AccountViewModel> {
  const password = decodeToken(token).split(':')[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.listOrganizations({ accountEmail: username });
}

// Cannot use apiclient since there is no token available
export async function createUser(requestParameters: CreateUserRequest): Promise<CreateUserResponse> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.createUser(requestParameters);
}

export function sendPassword(username: string): Promise<MessageViewModel> {
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.requestPassword({ userEmail: username });
}

export function checkEmail(username: string): Promise<MessageViewModel> {
  console.log('backend');
  const config = new Configuration({ fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.fetchUserStatus({ accountEmail: username });
}

export function savePrepareState(
  token: string,
  organizationId: string,
  message: AccountStateUpdateMessage,
): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new OrganizationApi(config);

  return api.updatePendingOrganizationState({
    organizationId: organizationId,
    accountStateUpdateMessage: message,
  });
}

export async function sendFeedback(token: string, message: FeedbackMessage): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new AccountsApi(config);

  await api.sendFeedback({ feedbackMessage: message });
}

export function restore(token: string, organizationId: number, message: RestoreCommand): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new OrganizationApi(config);

  return api.restoreDeletedEntity({
    organizationId: organizationId.toString(),
    restoreCommand: message,
  });
}

export function updateUser(token: string, message: AccountUpdateMessage): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.updatePassword({
    accountEmail: username,
    accountUpdateMessage: message,
  });
}

export function linkAuth(token: string, message: LinkAuthUpdateMessage): Promise<LinkAuthResult> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.startLinkAuthentication({
    linkAuthUpdateMessage: message,
  });
}

export function unlinkAuth(token: string, message: UnlinkAuthMessage): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new AccountsApi(config);

  return api.unlinkAuthentication({
    unlinkAuthMessage: message,
  });
}

export function updateSecureLogin(
  token: string,
  organizationId: number,
  message: UpdateSecureLoginRequest,
): Promise<void> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new OrganizationApi(config);

  return api.updateSecureLogin({
    updateSecureLoginRequest: message,
    organizationId: '' + organizationId,
  });
}

export function updateSubscription(
  token: string,
  organizationId: number,
  message: OrganizationSettingsUpdateMessage,
): Promise<void> {
  const strings = Buffer.from(token, 'base64').toString('binary').split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ basePath: '/api', username: username, password: password, fetchApi: fetch });
  const api = new OrganizationApi(config);

  return api.updateOrganizationSettings({
    organizationId: organizationId.toString(),
    organizationSettingsUpdateMessage: message,
  });
}

function assetAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new AssetsApi(config);
  return api.fetchAttachmentForAsset({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function constitutionalDocumentAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new ConstitutionalDocumentsApi(config);
  return api.fetchAttachmentForConstitutionalDocument({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function contractAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new ContractsApi(config);
  return api.fetchAttachmentForContract({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function documentAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new DocumentsApi(config);
  return api.fetchAttachmentForDocument({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function employeeAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new EmployeesApi(config);
  return api.fetchAttachmentForEmployee({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}

function eventOccurrenceAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new EventOccurrencesApi(config);
  return api.fetchAttachmentForEventOccurrence({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function meetingOccurrenceAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new MeetingOccurrencesApi(config);
  return api.fetchAttachmentForMeetingOccurrence({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function functionAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new FunctionsApi(config);
  return api.fetchAttachmentForFunction({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function guidelineAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new GuidelinesApi(config);
  return api.fetchAttachmentForGuideline({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function issueAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new IssuesApi(config);
  return api.fetchAttachmentForIssue({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function meetingAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new MeetingsApi(config);
  return api.fetchAttachmentForMeeting({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function partnerAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new PartnersApi(config);
  return api.fetchAttachmentForPartner({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function contactAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  partnerId: string,
): Promise<AttachmentViewModel> {
  const api = new PartnersApi(config);
  return api.fetchAttachmentForPartnerContact({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
    partnerId: partnerId,
  });
}
function reportAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new ReportsApi(config);
  return api.fetchAttachmentForReport({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function riskAssessmentAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new RiskAssessmentsApi(config);
  return api.fetchAttachmentForRiskAssessment({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function taskAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  functionId: string,
): Promise<AttachmentViewModel> {
  const api = new FunctionsApi(config);
  return api.fetchAttachmentForFunctionTask({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
    functionId: functionId,
  });
}
function externalConnectionAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new ComputersApi(config);
  return api.fetchAttachmentForExternalConnection({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}
function computerAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new ComputersApi(config);
  return api.fetchAttachmentForComputer({
    entityId: entityId,
    organizationId: organizationId.toString(),
    fileNameOrAttachmentUuid: attachmentId,
  });
}

function substanceAttachment(
  config: Configuration,
  entityId: string,
  organizationId: number,
  attachmentId: string,
): Promise<AttachmentViewModel> {
  const api = new SubstancesApi(config);
  return api.fetchAttachmentForSubstance({
    entityId: entityId,
    organizationId: organizationId.toString(),
    filenameOrAttachmentUuid: attachmentId,
  });
}

export function attachment(
  token: string,
  entityType: string,
  entityId: string,
  organizationId: number,
  attachmentId: string,
  parentId?: string,
): Promise<AttachmentViewModel> {
  const strings = Buffer.from(token, 'base64').toString('binary').split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({
    basePath: '/api',
    username: username,
    password: password,
    fetchApi: fetch,
  });

  if (entityId !== 'new') {
    switch (entityType) {
      case 'assets':
        return assetAttachment(config, entityId, organizationId, attachmentId);
      case 'constitutionalDocuments':
        return constitutionalDocumentAttachment(config, entityId, organizationId, attachmentId);
      case 'substances':
        return substanceAttachment(config, entityId, organizationId, attachmentId);
      case 'contracts':
        return contractAttachment(config, entityId, organizationId, attachmentId);
      case 'documents':
        return documentAttachment(config, entityId, organizationId, attachmentId);
      case 'employees':
        return employeeAttachment(config, entityId, organizationId, attachmentId);
      case 'eventOccurrences':
        return eventOccurrenceAttachment(config, entityId, organizationId, attachmentId);
      case 'meetingOccurrences':
        return meetingOccurrenceAttachment(config, entityId, organizationId, attachmentId);
      case 'functions':
        return functionAttachment(config, entityId, organizationId, attachmentId);
      case 'guidelines':
        return guidelineAttachment(config, entityId, organizationId, attachmentId);
      case 'issues':
        return issueAttachment(config, entityId, organizationId, attachmentId);
      case 'meetings':
        return meetingAttachment(config, entityId, organizationId, attachmentId);
      case 'partners':
        return partnerAttachment(config, entityId, organizationId, attachmentId);
      case 'contacts':
        return contactAttachment(config, entityId, organizationId, attachmentId, parentId ?? '');
      case 'reports':
        return reportAttachment(config, entityId, organizationId, attachmentId);
      case 'riskAssessments':
        return riskAssessmentAttachment(config, entityId, organizationId, attachmentId);
      case 'tasks':
        return taskAttachment(config, entityId, organizationId, attachmentId, parentId ?? '');
      case 'externalConnections':
        return externalConnectionAttachment(config, entityId, organizationId, attachmentId);
      case 'computers':
        return computerAttachment(config, entityId, organizationId, attachmentId);
    }
  }

  throw new Error('Illegal state (E541), unexpected attachment entity type ' + entityType);
}

function contractRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new ContractsApi(config);
  return api.listRevisionsForContract({
    contractId: entityId,
    organizationId: organizationId.toString(),
  });
}

function constitutionalDocumentRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new ConstitutionalDocumentsApi(config);
  return api.listRevisionsForConstitutionalDocument({
    constitutionalDocumentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function documentRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new DocumentsApi(config);
  return api.listRevisionsForDocument({
    documentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function draftForEntity(
  config: Configuration,
  entityId: string,
  organizationId: number,
  entityType: string,
): Promise<SaveDraftCommand> {
  const api = new OrganizationApi(config);
  return api.fetchDraft({
    entityType: entityType,
    entityId: entityId,
    organizationId: organizationId.toString(),
  });
}

function functionRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new FunctionsApi(config);
  return api.listRevisionsForFunction({
    functionId: entityId,
    organizationId: organizationId.toString(),
  });
}

function guidelineRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new GuidelinesApi(config);
  return api.listRevisionsForGuideline({
    guidelineId: entityId,
    organizationId: organizationId.toString(),
  });
}

function reportRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new ReportsApi(config);
  return api.listRevisionsForReport({
    reportId: entityId,
    organizationId: organizationId.toString(),
  });
}

function meetingRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new MeetingsApi(config);
  return api.listRevisionsForMeeting({
    meetingId: entityId,
    organizationId: organizationId.toString(),
  });
}

function riskAssessmentRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
): Promise<RevisionViewModel[]> {
  const api = new RiskAssessmentsApi(config);
  return api.listRevisionsForRiskAssessment({
    riskAssessmentId: entityId,
    organizationId: organizationId.toString(),
  });
}

function taskRevisions(
  config: Configuration,
  entityId: string,
  organizationId: number,
  parentEntityId: string,
): Promise<RevisionViewModel[]> {
  const api = new FunctionsApi(config);
  return api.listRevisionsForFunctionTask({
    functionId: parentEntityId,
    taskId: entityId,
    organizationId: organizationId.toString(),
  });
}

const supportedEntityTypesForDraft = [
  'assets',
  'constitutionalDocuments',
  'contracts',
  'employees',
  'documents',
  'functions',
  'guidelines',
  'issues',
  'meetings',
  'partners',
  'contacts',
  'reports',
  'riskAssessments',
  'substances',
  'tasks',
  'eventOccurrences',
  'meetingOccurrences',
];

export function draft(
  token: string,
  entityType: string,
  entityId: string,
  organizationId: number,
): Promise<SaveDraftCommand> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ username: username, password: password, fetchApi: fetch });

  if (entityId !== 'new' && supportedEntityTypesForDraft.includes(entityType)) {
    return draftForEntity(config, entityId, organizationId, entityType);
  }

  return Promise.reject(new Error('Entity type ' + entityType + ' not supported'));
}

export function revisions(
  token: string,
  entityType: string,
  entityId: string,
  organizationId: number,
  parentEntityId?: string,
): Promise<RevisionViewModel[]> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ username: username, password: password, fetchApi: fetch });

  if (entityId !== 'new') {
    switch (entityType) {
      case 'constitutionalDocuments':
        return constitutionalDocumentRevisions(config, entityId, organizationId);
      case 'contracts':
        return contractRevisions(config, entityId, organizationId);
      case 'documents':
        return documentRevisions(config, entityId, organizationId);
      case 'functions':
        return functionRevisions(config, entityId, organizationId);
      case 'guidelines':
        return guidelineRevisions(config, entityId, organizationId);
      case 'reports':
        return reportRevisions(config, entityId, organizationId);
      case 'meetings':
        return meetingRevisions(config, entityId, organizationId);
      case 'riskAssessments':
        return riskAssessmentRevisions(config, entityId, organizationId);
      case 'tasks':
        if (parentEntityId === undefined) {
          throw new Error('Illegal state (E601), arguments for task revisions, parent missing');
        }
        return taskRevisions(config, entityId, organizationId, parentEntityId);
    }
  }

  return Promise.resolve([]);
}

const debounceSearch = pDebounce((token: string, query: string, organizationId: string) => {
  return search(token, query, organizationId);
}, 300);
export function debouncedSearch(token?: string, query?: string, organizationId?: string): Promise<SearchResultHit[]> {
  if (token === undefined || query === undefined || organizationId === undefined) {
    return Promise.resolve([]);
  }
  if (query.length < 3) {
    return Promise.resolve([]);
  }
  return debounceSearch(token, query, organizationId);
}

export async function search(token: string, query: string, organizationId: string): Promise<SearchResultHit[]> {
  const strings = decodeToken(token).split(':');
  const username = strings[0];
  const password = strings[1];
  const config = new Configuration({ username: username, password: password, fetchApi: fetch });
  const api = new OrganizationApi(config);
  return api.searchOrganization({
    query: query,
    limit: 200,
    organizationId: organizationId,
  });
}

export function saveDraft(
  token: string,
  organizationId: string,
  entityType: string,
  entityId: string,
  properties: Record<string, unknown>,
) {
  fetch(BASE_PATH + '/organizations/' + organizationId + '/drafts/' + entityType + '/' + entityId, {
    headers: {
      Accept: 'application/json',
      'Content-Type': 'application/json',
      Authorization: 'Basic ' + token,
    },
    method: 'PUT',
    body: JSON.stringify(properties),
  })
    .then(function () {
      console.log('Saved draft', organizationId, entityType, entityId);
    })
    .catch(function (res) {
      console.log(res);
    });
}
