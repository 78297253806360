import Ably from 'ably/callbacks';
import { customElement } from 'lit/decorators.js';
import { ContentEditDoneDetail, RelateAssetToEventOccurrencesDetail } from 'src/content/d-content.js';
import {
  AppState,
  AppStateAccount,
  AppStateLink,
  AppStateLoading,
  AppStateNotFound,
  AppStateNoUser,
  AppStateSecured,
  AppStateSignin,
  AppStateSignup,
  DAppCore,
  MapElementChangedDetail,
} from 'src/d-app-core.js';
import type { Subscription } from 'src/layout/parts/d-organization-subscription.js';
import type { TutorialParticipant } from 'src/library/lists/d-list-section-tutorial-participants.js';
import type {
  DeleteTimeCorrectionEvent,
  UpdateTimeCorrectionEvent,
} from 'src/library/lists/d-timekeeping-list-section.js';
import { mapSearchResultHits } from 'src/models/search.js';
import type { EditPeriodResult } from 'src/pages/edit-periods-dialog.js';
import type { OrganizationEditItem } from 'src/pages/organization-page/d-organization-edit.js';
import type { ShareVacationResult } from 'src/pages/staffing-page/share-staffing-dialog.js';
import { handleNavigation, logOut, reduxConnectMixin } from 'src/redux-behavior.js';
import * as dabihStore from 'src/store';
import {
  checkEmail,
  createAsset,
  createClient,
  createContract,
  createEmployee,
  createPartner,
  createRiskAssessment,
  createUser,
  debouncedSearch,
  declineTaskTemplateUpdate,
  declineTemplateUpdate,
  deleteAttachment,
  deleteItem,
  fetchPageGroups,
  getPages,
  getStore,
  loadedPageGroups,
  loadOrganization,
  loginUserAction,
  RecurrenceRule,
  renameAttachment,
  restore,
  saveItem,
  savePrepareState,
  sendFeedback,
  sendPassword,
  sendShareVacation,
  stopShareVacation,
  taskDone,
  updateCurrentTutorialId,
  updateHelpViewerOpen,
  updateItem,
  updateOrganization,
  updateSecureLogin,
  updateSelectedStartTask,
  updateStaffingCalendarYear,
  updateSubscription,
  updateTutorialViewerOpen,
  updateUser,
  updateUserAction,
  updateUserPropertiesForOrganization,
  updateWeekStart,
  uploadFile,
} from 'src/store';
import {
  AccountUpdateMessage,
  ConnectEventOccurrencesRequest,
  CreateMeetingOccurrenceReportRequest,
  DeleteEventOccurrenceRequest,
  DeleteMeetingOccurrenceRequest,
  EmployeeViewModelGenderEnum,
  FunctionUpdateMessage,
  IssueUpdateMessage,
  LeavePeriod,
  MarkEventOccurrenceDoneRequest,
  MarkEventOccurrenceNotDoneRequest,
  OrganizationReference,
  OrganizationSettingsUpdateMessageInvoiceSendMethodEnum,
  OrganizationSettingsUpdateMessageSpecialTermsEnum,
  OrganizationUpdateMessage,
  PlusTimePeriod,
  RepeatEventOccurrenceRequest,
  RepeatMeetingOccurrenceRequest,
  SaveDraftRequest,
  SendMeetingOccurrenceNoticeRequest,
  SendMeetingOccurrenceReportRequest,
  TimekeepingCorrection,
  UpdateEventOccurrenceRelatedAssetRequest,
  UpdateEventOccurrenceRequest,
  UpdateMeetingOccurrenceRequest,
  UpdateSpecialTermsCommandSpecialTermsEnum,
  UpdateStaffingAccessRequest,
  UpdateTutorialRequest,
  UpdateTutorialStateForEmployeeRequest,
  WorkSchedule,
  WorkScheduleException,
} from 'src/store/api';
import {
  AccountStateViewModel,
  AccountUpdateMessageReceiveRemindersEnum,
  AccountUpdateMessageSummariesEnum,
  OrganizationSettingsUpdateMessage,
  SendMessageCommandMessageTypeEnum,
  UpdateMeetingOccurrenceCommandClassificationEnum,
} from 'src/store/api';
import { displayAlert } from 'src/utilities/display-alert.js';
import { LocalDate } from 'src/utilities/local-date.js';
import { uuid } from 'src/utilities/text.js';
import { applicationViewModel } from './models/application-view-model.js';
import type { State, User } from './store/types.js';
import type { TutorialState } from 'src/outskirts/d-tutorial-viewer.js';
import { updateMessage } from 'src/d-app--update-message.js';
import type { MessageForSend } from 'src/content/meeting-occurrences/meeting-message-dialog.js';
import type { EmployeeWithStaffGroup } from 'src/pages/staffing-page/staffing-groups-dialog.js';
import { dataItemChanged } from 'src/d-app--data-item-changed.js';
import type { ComputerNetworkChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-computers-network.js';
import type { NetworkExternalConnectionChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-network-external-connections.js';
import type { AccessChange } from 'src/pages/computers-page/infosec-procedure/d-infosec-access.js';
import type { BackupChange } from 'src/pages/computers-page/infosec-procedure/editors/d-edit-backup.js';
import {
  accessChangeHandler,
  backupChangeHandler,
  computerNetworkChangeHandler,
  dataItemsRemovedHandler,
  externalConnectChangeHandler,
  mapElementChangedHandler,
  mapElementDeletedHandler,
} from 'src/handlers/computers-handlers.js';
import type { EditException } from 'src/pages/staffing-page/staffing-employee-day-dialog.js';
import type { Types } from 'ably/promises';
import type { FileViewerDocument } from 'src/layout/parts/file-viewer-dialog.js';
import { findAvailableTutorials } from 'src/models/tutorials.js';
import {
  deleteEmployeeLeavePeriod,
  deleteEmployeePlusTimePeriod,
  deleteEmployeeTimekeepingCorrection,
  deleteEmployeeWorkScheduleException,
  updateEmployeeAccess,
  updateEmployeeGender,
  updateEmployeeLeavePeriod,
  updateEmployeePlusTimePeriod,
  updateEmployeeStaffGroup,
  updateEmployeeTimekeepingCorrection,
  updateEmployeeWorkScheduleException,
  updateEmployeeWorkSchedules,
} from 'src/store/backend-employee.js';
import {
  breadCrumbSelectedName,
  currentEmployeeAsViewModel,
  currentEmployeeShortName,
  currentEntityTypeNameDetermined,
  currentPathArray,
  employeeNamesById,
  getItemDataFromTemplateId,
  getOrganization,
  ignoredRobotAlerts,
  isPageId,
  itemsByTypeAndId,
  partnerNamesById,
  writeAccess,
} from 'src/store/selectors';
import type { UpdateUserAccessEvent } from 'src/layout/parts/d-organization-edit-access.js';
import { produce } from 'immer';
import _, { isEqual } from 'lodash';
import { CreateContactInput, CreateEntityInput } from 'src/layout/parts/d-new-document';
import { handleCreateEntity } from 'src/d-app-create-entity-handler';
import { newItemLabels } from 'src/utilities/new-item-labels';
import { CreateEmployeeInput } from 'src/content/meeting-occurrences/d-meeting-occurrence-view';
import type { EmployeeViewEditItem } from 'src/content/employees/d-employee-view';
import { PartnerViewEditItem } from 'src/content/partners/d-partner-view';
import { CreateContactAndPartnerInput } from 'src/layout/parts/d-new-contact-and-partner-dialog';
import { ContactViewEditItem } from 'src/content/contacts/d-contact-view';
import { getFeatureStates } from 'src/store/selectors/features';
import type { ZoomUnit } from 'src/pages/staffing-page/d-staffing-calendar-data';
import { NewOrganization, UserForSignup } from 'src/outskirts/signup/d-signup-form';

@customElement('d-app')
class DApp extends reduxConnectMixin(DAppCore) {
  private employeeNamesById: { [uuid: string]: string } = {};
  private partnerNamesById: { [uuid: string]: string } = {};
  private currentAblyTokenRequest?: string;

  private api = createClient();
  private lastDraftSaved?: SaveDraftRequest;

  private debouncedDoStateChanged = _.debounce((state: State) => this.doStateChanged(state), 400, {
    trailing: true,
    leading: true,
  });

  stateChanged(state: State) {
    this.debouncedDoStateChanged(state);
  }

  doStateChanged(state: State) {
    console.log('state changed');
    this.updateAppState(state).then(() => console.log('app state updated'));
  }

  subscribeToAbly(user: User, organizationId: number) {
    if (user.ablyTokenRequest && this.currentAblyTokenRequest !== user.ablyTokenRequest) {
      this.currentAblyTokenRequest = user.ablyTokenRequest;
      const lastToken: Ably.Types.TokenRequest | undefined = JSON.parse(user.ablyTokenRequest);
      const ablyClient = this.createAblyClient(lastToken, user);
      const org = user.organizations.find((o) => o.id === organizationId);
      this.startListeningToChannels(
        ablyClient,
        organizationId,
        user.token,
        org !== undefined && org.userType === 'CONTACT' ? '-contact' : '',
      );
    }
  }

  async onWeekStartChanged(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(updateWeekStart(e.detail.value));
  }

  async onStaffingCalendarUserDisplaySelectionChanged(e: CustomEvent<{ start: string; end: string; zoom: ZoomUnit }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          { staffingCalendarUserDisplaySelection: e.detail },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  /**
   * For utvikling
   *
   */
  async onUpdateUiSettings(e: CustomEvent<{ isAppPlatform: boolean; isSimplifiedUi: boolean }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      console.log('onUpdateUiSettings');
      await getStore().dispatch(
        updateUserPropertiesForOrganization({ uiSettings: e.detail }, this.appState.viewModel.currentOrganizationId),
      );
    }
  }

  async onSaveHideDoneOption(e) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      await getStore().dispatch(
        updateUserPropertiesForOrganization(
          { todoListHideAfterDaysOption: e.detail.value },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onUploadFile(e: CustomEvent<{ file: File; callback: () => void; type: string; uuid: string }>) {
    console.log('upload file', e.detail);
    await getStore().dispatch(uploadFile(e.detail.type, e.detail.uuid, e.detail.file));
    e.detail.callback();
  }

  onUpdateSubstanceFile(
    e: CustomEvent<{ files: File[]; substanceId: string; callback: (data: { name: string }[]) => void }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const formData = new FormData();
      console.log('Files ' + e.detail.files[0].name);
      let hasFiles = false;
      [...e.detail.files].forEach(function (file) {
        const suffix = file.name.slice(-3).toLowerCase();
        if (suffix === 'pdf' || file.type === '') {
          console.log('file', file);
          formData.append(file.name, file);
          hasFiles = true;
        }
      });
      if (hasFiles) {
        const url =
          'https://api.trinnvis.io/organizations/' +
          this.appState.viewModel.currentOrganizationId +
          '/substances/' +
          e.detail.substanceId +
          '/update-file';
        fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Basic ' + this.appState.user.token,
          },
        })
          .then((r) => r.json())
          .then((data) => {
            console.log('substances', data);
            e.detail.callback(data ?? []);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }

  async onUploadSubstanceFiles(e: CustomEvent<{ files: File[]; callback: (data: { name: string }[]) => void }>) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const formData = new FormData();
      let hasFiles = false;
      [...e.detail.files].forEach(function (file) {
        const suffix = file.name.slice(-3).toLowerCase();
        if (suffix === 'pdf' || file.type === '') {
          console.log('file', file);
          formData.append(file.name, file);
          hasFiles = true;
        }
      });
      if (hasFiles) {
        const url =
          'https://api.trinnvis.io/organizations/' + this.appState.viewModel.currentOrganizationId + '/substances';
        fetch(url, {
          method: 'POST',
          body: formData,
          headers: {
            Authorization: 'Basic ' + this.appState.user.token,
          },
        })
          .then((r) => r.json())
          .then((data) => {
            console.log('substances', data);
            e.detail.callback(data ?? []);
          })
          .catch((e) => {
            console.error(e);
          });
      }
    }
  }

  onResetSubstances() {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const url =
        'https://api.trinnvis.io/organizations/' + this.appState.viewModel.currentOrganizationId + '/substances/:reset';
      const formData = new FormData();
      fetch(url, {
        method: 'POST',
        body: formData,
        headers: {
          Authorization: 'Basic ' + this.appState.user.token,
        },
      })
        .then(() => {
          console.log('Reseting');
        })
        .catch((e) => {
          console.error(e);
        });
      alert('Sletter data. Dette kan ta inntil 5 minutter.');
    }
  }

  async onSaveTimekeepingPeriod(e: CustomEvent<EditPeriodResult>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: PlusTimePeriod = {
        confirmed: e.detail.confirmed,
        end: e.detail.end.split(' ')[0],
        notes: e.detail.notes,
        start: e.detail.start.split(' ')[0],
        uuid: e.detail.periodId ?? uuid(),
        startTime: e.detail.start.split(' ')[1] ?? '',
        endTime: e.detail.end.split(' ')[1] ?? '',
      };

      await updateEmployeePlusTimePeriod(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        m,
      );
    }
  }

  async onDeleteTimekeepingPeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await deleteEmployeePlusTimePeriod(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        e.detail.periodId,
      );
    }
  }

  async onSaveLeavePeriod(e: CustomEvent<EditPeriodResult>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: LeavePeriod = {
        days: e.detail.type === 'sickLeave' && e.detail.grade < 100 ? e.detail.days : [],
        grade: e.detail.type === 'sickLeave' ? e.detail.grade : 100,
        type: e.detail.type,
        confirmed: e.detail.confirmed,
        end: e.detail.end.split(' ')[0],
        notes: e.detail.notes,
        start: e.detail.start.split(' ')[0],
        uuid: e.detail.periodId ?? uuid(),
        startTime: e.detail.start.split(' ')[1],
        endTime: e.detail.end.split(' ')[1],
      };

      await updateEmployeeLeavePeriod(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        m,
      );
    }
  }

  async onDeleteLeavePeriod(e: CustomEvent<{ employeeUuid: string; periodId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await deleteEmployeeLeavePeriod(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        e.detail.periodId,
      );
    }
  }

  async onSaveStaffingAccess(e: CustomEvent<{ accessList: string[]; leavePeriodEditRestriction: boolean }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: UpdateStaffingAccessRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateStaffingAccessCommand: {
          staffingCalendarAccessList: e.detail.accessList,
          leavePeriodEditRestriction: e.detail.leavePeriodEditRestriction,
        },
      };

      await this.api.organization.updateStaffingAccess(p);
    }
  }

  async onRepeatMeetingOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: RepeatMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        repeatMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          dateTimesToAdd: e.detail.dateTimesToAdd,
          occurrencesToRemove: e.detail.occurrencesToRemove,
        },
      };

      await this.api.meetingOccurrences.repeatMeetingOccurrence(p);
    }
  }

  async onRepeatEventOccurrence(
    e: CustomEvent<{
      uuid: string;
      dateTimesToAdd: string[];
      occurrencesToRemove: string[];
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p: RepeatEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        repeatEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          dateTimesToAdd: e.detail.dateTimesToAdd,
          occurrencesToRemove: e.detail.occurrencesToRemove,
        },
      };

      await this.api.eventOccurrences.repeatEventOccurrence(p);
    }
  }

  async onUpdateEmployeeWorkSchedules(
    e: CustomEvent<{
      employeeUuid: string;
      workSchedules: WorkSchedule[];
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await updateEmployeeWorkSchedules(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        e.detail.workSchedules,
      );
    }
  }

  onStaffingYearChanged(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(updateStaffingCalendarYear(e.detail.value));
  }

  async onUpdateEmployeeStaffGroups(e: CustomEvent<{ value: EmployeeWithStaffGroup[] }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      for (const employee of e.detail.value) {
        await updateEmployeeStaffGroup(
          this.appState.user.token,
          this.appState.viewModel.currentOrganizationId,
          employee.uuid,
          employee.staffGroup,
        );
      }
    }
  }

  onUpdateDeclined(e: CustomEvent<{ entityType: string; entityId: string }>) {
    if (e.detail.entityType === 'tasks') {
      getStore().dispatch(declineTaskTemplateUpdate(e.detail.entityId));
    } else {
      getStore().dispatch(declineTemplateUpdate(e.detail.entityType, e.detail.entityId));
    }
  }

  async onContentSaveEvent(e: CustomEvent<ContentEditDoneDetail>) {
    if (
      this.appState &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded &&
      e.detail.type === 'eventOccurrences'
    ) {
      const p: UpdateEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          alerts: e.detail.alert,
          alertMessage: e.detail.message,
          alertIncludesDetails: e.detail.includeDetailsInAlert,
          name: e.detail.editItem.type === 'standard' ? e.detail.editItem.name : '',
          date: e.detail.editItem.date,
          time: e.detail.editItem.time === 'NONE' ? '' : e.detail.editItem.time,
          durationMinutes: e.detail.editItem.durationMinutes,
          employees: e.detail.editItem.assignedToEmployees,
          contacts: e.detail.editItem.assignedToContacts,
          functionUuid: e.detail.editItem.assignedToFunction,
          assets: e.detail.editItem.assets,
          persistent: e.detail.editItem.persistent,
          reminder: e.detail.editItem.reminder,
          notes: e.detail.editItem.notes,
          description: e.detail.editItem.procedures,
          taskUuid: e.detail.editItem.taskUuid,
        },
      };

      await this.api.eventOccurrences.updateEventOccurrence(p);
      console.log('saving after');
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    }
  }

  async onRelateAssetToEventOccurrences(e: CustomEvent<RelateAssetToEventOccurrencesDetail>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      console.log('onRelateAssetToEventOccurrences', e.detail);
      const p: ConnectEventOccurrencesRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        assetId: e.detail.asset,
        assetConnectEventOccurrencesCommand: {
          eventOccurrenceIds: e.detail.eventOccurrences,
        },
      };

      await this.api.assets.connectEventOccurrences(p);
    }
  }

  async onContentSaveMeetingOccurrence(e: CustomEvent<ContentEditDoneDetail>) {
    if (
      this.appState &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded &&
      e.detail.type === 'meetingOccurrences'
    ) {
      const p: UpdateMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          name: e.detail.editItem.name,
          date: e.detail.editItem.date,
          time: e.detail.editItem.time,
          durationMinutes: e.detail.editItem.durationMinutes,
          participants: e.detail.editItem.assignedToEmployees,
          externalParticipants: e.detail.editItem.assignedToContacts,
          reminder: e.detail.editItem.reminder,
          agenda: e.detail.editItem.meetingAgenda,
          classification: e.detail.editItem.classification as UpdateMeetingOccurrenceCommandClassificationEnum,
          accessControl: e.detail.editItem.accessControl,
          responsible: e.detail.editItem.meetingResponsibleFunctionUuid,
          responsibleEmployee: e.detail.editItem.meetingResponsibleEmployeeUuid,
        },
      };

      await this.api.meetingOccurrences.updateMeetingOccurrence(p);
      console.log('saving after');
      if (e.detail.afterSave) {
        await e.detail.afterSave();
      }
    }
  }

  async onContentDeleteEvent(
    e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string; alert: boolean }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      window.history.pushState({}, '', e.detail.url);
      await handleNavigation(window.location);
      const p: DeleteEventOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        deleteEventOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
          alerts: e.detail.alert,
        },
      };

      await this.api.eventOccurrences.deleteEventOccurrence(p);
    }
  }

  async onContentDeleteMeetingOccurrence(
    e: CustomEvent<{ url: string; type: string; uuid: string; saveType: string; alerts: boolean }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      window.history.pushState({}, '', e.detail.url);
      await handleNavigation(window.location);
      const p: DeleteMeetingOccurrenceRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        deleteMeetingOccurrenceCommand: {
          uuid: e.detail.uuid,
          saveType: e.detail.saveType === 'single' || e.detail.saveType === 'singleRepeated' ? 'THIS' : 'FUTURE',
        },
      };

      await this.api.meetingOccurrences.deleteMeetingOccurrence(p);
    }
  }

  /*
                   Must navigate away from the item to be deleted before actually deleting.
                   */
  async onEntityDelete(e: CustomEvent<{ url: string; type: string; uuid: string }>) {
    window.history.pushState({}, '', e.detail.url);
    await handleNavigation(window.location);
    getStore().dispatch(deleteItem(e.detail.type, e.detail.uuid));
  }

  async onRequestLogin(
    e: CustomEvent<{ user: { username: string; password: string }; loginFailed: () => void; target?: string }>,
  ) {
    const user = e.detail.user;

    const result = await getStore().dispatch(loginUserAction(user.username, user.password));
    console.log(result);
    if (result && e.detail.target !== undefined) {
      setTimeout(() => {
        window.location.href = e.detail.target ?? '/';
      }, 200);
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else if (result) {
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else {
      console.log('Login not success');
      e.detail.loginFailed();
    }
  }

  async onRequestPassword(e: CustomEvent<{ email: string }>) {
    await sendPassword(e.detail.email);
  }

  async onSavePrepareState(
    e: CustomEvent<{
      organizationId: string;
      state: AccountStateViewModel;
      saveToServer: boolean;
      afterSave: (() => void) | undefined;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account') {
      if (e.detail.saveToServer) {
        await savePrepareState(this.appState.user.token, e.detail.organizationId, e.detail.state);
        console.log('State saved');
      }
      const store = getStore();
      const state = store.getState();
      if (state.user) {
        const user = produce(state.user, (draft) => {
          const organization = draft.organizations.find((o) => '' + o.id === e.detail.organizationId);
          if (organization) organization.state = e.detail.state;
        });
        await store.dispatch(updateUserAction(user));
        if (e.detail.afterSave) {
          e.detail.afterSave();
        }
      }
    }
  }

  async _requestLink(e) {
    const user = e.detail;

    const result = await getStore().dispatch(loginUserAction(user.username, user.password));
    console.log(result);
    if (result) {
      window.history.pushState({}, '', '/account');
      await handleNavigation(window.location);
    } else {
      console.log('Login not success');
      // e.detail.loginFailed();
    }
  }

  async onRequestLogout(e: CustomEvent) {
    logOut(e.detail?.redirect ?? false);
  }

  async onDeleteTimeCorrection(e: DeleteTimeCorrectionEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await deleteEmployeeTimekeepingCorrection(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        e.detail.uuid,
      );
    }
  }

  async onUpdateTimeCorrection(e: UpdateTimeCorrectionEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const m: TimekeepingCorrection = {
        correctionType: e.detail.correctionType,
        date: e.detail.date,
        hours: e.detail.hours,
        notes: e.detail.notes,
        uuid: e.detail.uuid,
      };
      await updateEmployeeTimekeepingCorrection(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.employeeUuid,
        m,
      );
    }
  }

  async onTutorialChanged(
    e: CustomEvent<{
      uuid: string;
      participants: TutorialParticipant[];
      isCommonDate: boolean;
      commonDate: string;
      comment: string;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      const p: UpdateTutorialRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        updateTutorialCommand: {
          id: Number.parseInt(e.detail.uuid),
          participants: e.detail.participants.map((e) => {
            return {
              ...e,
              statusComment: e.statusComment ?? '',
            };
          }),
          dueDate: e.detail.isCommonDate ? e.detail.commonDate : undefined,
          comment: e.detail.comment,
        },
      };

      await this.api.organization.updateTutorial(p);
    }
  }

  async onToggleHelp(e: CustomEvent<{ value: boolean }>) {
    getStore().dispatch(updateHelpViewerOpen(e.detail.value));
    getStore().dispatch(updateTutorialViewerOpen(false));
  }

  async onToggleTutorial(e: CustomEvent<{ value: boolean }>) {
    const state = getStore().getState();
    if (state.currentTutorialId === undefined) {
      const organization = getOrganization(state);
      if (organization === undefined) {
        throw new Error('Illegal state (E487), expected organization');
      }
      const activeTutorials = findAvailableTutorials(state, organization, currentEmployeeAsViewModel(state));
      const firstTutorial = activeTutorials[0];
      getStore().dispatch(updateCurrentTutorialId(firstTutorial.value));
    }
    getStore().dispatch(updateTutorialViewerOpen(e.detail.value));
    getStore().dispatch(updateHelpViewerOpen(false));
  }

  onSaveOrganization(e: CustomEvent<OrganizationEditItem>) {
    const state = getStore().getState();
    const organization = getOrganization(state);
    if (organization !== undefined) {
      const updateMessage: OrganizationUpdateMessage = {
        address: organization.address ?? '',
        notes: e.detail.notes,
        organizationNumber: e.detail.organizationNumber,
        activityCodes: e.detail.activityCodes.slice(),
        businessEntityType: e.detail.businessEntityType,
        mailAddress: e.detail.mailAddress,
        officeAddress: e.detail.officeAddress,
        sector: e.detail.sector,
        type: organization.type ?? '',
        mailPostcode: organization.mailPostcode ?? '',
        url: e.detail.url,
        officePostcode: organization.officePostcode ?? '',
        phone: e.detail.phone,
        name: e.detail.name,
        herNumber: e.detail.herNumber,
        fax: e.detail.fax,
        email: e.detail.email,
      };
      getStore().dispatch(updateOrganization(updateMessage));
    }
  }

  async onContentEditDone(e: CustomEvent<ContentEditDoneDetail>) {
    const entityUuid = e.detail.uuid === '' ? uuid() : e.detail.uuid;
    const message = updateMessage(getStore().getState(), e.detail);
    let parentId: string | undefined = undefined;
    if (e.detail.type === 'contacts') {
      parentId = e.detail.editItem.partnerId;
    } else if (e.detail.type === 'tasks') {
      parentId = e.detail.editItem.functionUuid;
    }

    const entityUuidWithOutInstanceDate = entityUuid.split(':')[0];
    console.log('saving saveitem');
    await getStore().dispatch(saveItem(e.detail.type, entityUuidWithOutInstanceDate, message, parentId));
    console.log('saving saveitem done');

    console.log('saving after');
    if (e.detail.afterSave) {
      await e.detail.afterSave();
    }
  }

  async onSearchQueryChanged(e: CustomEvent<{ query: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      this.searchQuery = e.detail.query;
      const o = getStore().getState().organization;
      if (o !== undefined) {
        const hits = await debouncedSearch(
          this.appState.user.token,
          this.searchQuery,
          String(this.appState.viewModel.currentOrganizationId),
        );
        this.searchResults = mapSearchResultHits(
          this.appState.viewModel.currentOrganizationId,
          hits,
          o.singleUser,
          o,
          this.employeeNamesById,
          this.partnerNamesById,
        );
      }
    }
  }

  onUpdateHelp(e: CustomEvent<{ page: string }>) {
    getStore().dispatch(dabihStore.updateCurrentHelpPage(e.detail.page));
  }

  onUpdateTutorial(e: CustomEvent<{ value: string }>) {
    getStore().dispatch(dabihStore.updateCurrentTutorialId(e.detail.value));
  }

  async onUpdateEventAsset(
    e: CustomEvent<{
      checkedBy: string;
      checkedTime: string;
      status: string;
      comments: string;
      assetUuid: string;
      eventOccurrenceUuid: string;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (!(e.detail.status === 'NONE' || e.detail.status === 'OK' || e.detail.status === 'NOT_OK')) {
        throw new Error('Illegal state (E343) ' + e.detail.status);
      }

      const p: UpdateEventOccurrenceRelatedAssetRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        eventOccurrenceRelatedAssetUpdateMessage: {
          uuid: e.detail.eventOccurrenceUuid,
          assetStatus: e.detail.status,
          comment: e.detail.comments,
          assetUuid: e.detail.assetUuid,
        },
      };

      await this.api.eventOccurrences.updateEventOccurrenceRelatedAsset(p);
    }
  }

  onOpenPage(e: CustomEvent<{ pageId: number; queryString: string }>) {
    const state = getStore().getState();
    const items = itemsByTypeAndId(state);
    const pages = getPages(state);
    const organization = getOrganization(state);

    let target = '';
    // module meetings has a custom page for both employees and partners
    if (
      (e.detail.pageId === 996526 || e.detail.pageId === 312616) &&
      !getFeatureStates(state).core &&
      getFeatureStates(state).meetings
    ) {
      target = '476666';
    } else if (e.detail.pageId === 14785) {
      target = '65/tutorials/1';
    } else if (e.detail.pageId === 14571) {
      target = '65/tutorials/2';
    } else if (e.detail.pageId === 0) {
      target = '695944?showUserSettings';
    } else if (isPageId(e.detail.pageId, pages)) {
      target = '' + e.detail.pageId;
    }
    console.log('open page target', target);
    if (items && organization) {
      console.log('open page looking for item-data');

      const itemData = getItemDataFromTemplateId('' + e.detail.pageId, items, organization.singleUser);
      let startTask = '';
      if (itemData) {
        console.log('open page found item-data', itemData);
        if (itemData.startTask) {
          startTask = itemData.target;
          target = '695944';
        } else {
          target = itemData.target;
        }
      }
      const url =
        '/account/' +
        organization.organizationId +
        '/' +
        target +
        (e.detail.queryString ? '?' + e.detail.queryString : '');
      console.log('navigate to', url);
      setTimeout(() => {
        this.dispatchEvent(
          new CustomEvent<{ href: string }>('navigate', { bubbles: true, composed: true, detail: { href: url } }),
        );
      }, 200);
      if (startTask) {
        this.dispatchAction(dabihStore.updateSelectedStartTask(startTask));
      }
    }
    if (this.windowWidth < 801) {
      this.dispatchAction(dabihStore.updateHelpViewerOpen(false));
    }
  }

  onShare(e: CustomEvent<{ message: string; recipients: string[] }>) {
    const state = getStore().getState();
    const entityTypeNameDetermined = currentEntityTypeNameDetermined(state) ?? 'siden';
    const entityName = breadCrumbSelectedName(state);
    const currentEmployeeName = currentEmployeeShortName(state);
    const subject = currentEmployeeName + ' har sendt deg en lenke til ' + entityTypeNameDetermined + ' ' + entityName;

    const p = state.path;
    const s = p.split('/');

    const urlForMessage = s.length > 3 ? '/' + s.slice(3).join('/') : '';

    const c = {
      subject: subject,
      message: e.detail.message,
      url: urlForMessage,
      employees: e.detail.recipients,
      messageType: SendMessageCommandMessageTypeEnum.Email,
    };
    getStore().dispatch(dabihStore.sendMessage(c));
  }

  async onToggleEventDone(
    e: CustomEvent<{
      eventUuid: string;
      eventInstance?: string;
      uuid: string;
      done: boolean;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.done) {
        const p: MarkEventOccurrenceDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markEventOccurrenceDoneMessage: {
            uuid: e.detail.uuid,
          },
        };
        await this.api.eventOccurrences.markEventOccurrenceDone(p);
      } else {
        const p: MarkEventOccurrenceNotDoneRequest = {
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          markEventOccurrenceNotDoneMessage: {
            uuid: e.detail.uuid,
          },
        };
        await this.api.eventOccurrences.markEventOccurrenceNotDone(p);
      }
    }
  }

  onShareVacation(e: CustomEvent<ShareVacationResult>) {
    getStore().dispatch(sendShareVacation(e.detail));
  }

  async onShowShareVacation(e: CustomEvent<{ email: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const result = await this.api.organization.fetchSharedVacationsLink({
        organizationId: this.appState.viewModel.currentOrganizationId,
        email: e.detail.email,
      });
      console.log(result);
      window.open(result.location, 'super');
    }
  }

  onUnShareVacation(e: CustomEvent<{ recipient: string }>) {
    getStore().dispatch(stopShareVacation(e.detail));
  }

  onStartTaskSelected(e: CustomEvent<{ uuid: string }>) {
    getStore().dispatch(updateSelectedStartTask(e.detail.uuid));
  }

  onStartTaskClosed() {
    getStore().dispatch(updateSelectedStartTask(''));
  }

  onIgnoreRobot() {
    const state = getStore().getState();
    const e = LocalDate.fromString(state.today).plusDays(1);
    getStore().dispatch(
      dabihStore.updateUserProperties({
        ignoreRobotUntil: e.toString(),
      }),
    );
  }

  onIgnoreThisRobotAlert(e: CustomEvent<{ id: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const alerts = ignoredRobotAlerts(state);
      const updatedIgnoredRobotAlerts = [...alerts, e.detail.id];
      getStore().dispatch(
        dabihStore.updateUserPropertiesForOrganization(
          { ignoredRobotAlerts: updatedIgnoredRobotAlerts },
          this.appState.viewModel.currentOrganizationId,
        ),
      );
    }
  }

  async onAssignFunction(e: CustomEvent<{ functionUuid: string; employeeUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const item = o.functionsById[e.detail.functionUuid];
        if (item !== undefined) {
          const employees =
            item.type === 'SINGLE' ? [e.detail.employeeUuid] : [...item.employees, e.detail.employeeUuid];
          const updateMessage: FunctionUpdateMessage = {
            name: item.name,
            template: item.template,
            category: item.category,
            rotations: item.rotations.map((r) => ({
              employee: r.employee,
              day1: '' + r.day1,
              day2: '' + r.day2,
              day3: '' + r.day3,
              day4: '' + r.day4,
              day5: '' + r.day5,
              day6: '' + r.day6,
              day7: '' + r.day7,
              startDate: r.startDate,
            })),
            status: item.status,
            rotation: item.rotation,
            description: item.description,
            type: item.type,
            employees: employees,
            responsibility: item.responsibility,
            pages: [...item.pages],
            isConfirmedEntity: true,
          };
          await getStore().dispatch(saveItem('functions', e.detail.functionUuid, updateMessage));
        }
      }
    }
  }

  async onUpdateUser(e: CustomEvent<{ email: string; password?: string; alerts: string; receiveReminders: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const message: AccountUpdateMessage = {
          email: e.detail.email,
          password: e.detail.password,
          organizationIdForSummaries: o.organizationId,
          summaries: e.detail.alerts as AccountUpdateMessageSummariesEnum,
          receiveReminders: e.detail.receiveReminders as AccountUpdateMessageReceiveRemindersEnum,
        };
        await updateUser(state.token ?? '', message);
        if (e.detail.email !== this.appState.user.username) {
          displayAlert('Epost er endret. Vennligst logg inn på nytt.');
          this.onRequestLogout(new CustomEvent('request-logout', { bubbles: true, composed: true }));
        } else if (e.detail.password) {
          const user = {
            username: this.appState.user.username,
            password: e.detail.password,
          };
          await this.onRequestLogin(
            new CustomEvent('request-login', {
              bubbles: true,
              composed: true,
              detail: { user, loginFailed: () => console.error('Login failed from update user') },
            }),
          );
        }
      }
    }
  }

  async onAddFeatures(e: CustomEvent<{ features: string[] }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        for (const feature of e.detail.features) {
          await this.api.organization.addFeature({
            organizationId: '' + o.organizationId,
            addFeatureCommand: {
              feature,
            },
          });
        }
      }
    }
  }

  async onToggleFeature(e: CustomEvent<{ feature: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const f = o.features ?? [];
        if (f.includes(e.detail.feature)) {
          await this.api.organization.removeFeature({
            organizationId: '' + o.organizationId,
            removeFeatureCommand: {
              feature: e.detail.feature,
            },
          });
        } else {
          await this.api.organization.addFeature({
            organizationId: '' + o.organizationId,
            addFeatureCommand: {
              feature: e.detail.feature,
            },
          });
        }
      }
    }
  }

  async onUpdateSpecialTerms(e: CustomEvent<{ specialTerms: UpdateSpecialTermsCommandSpecialTermsEnum }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.organization.updateSpecialTerms({
          organizationId: '' + o.organizationId,
          updateSpecialTermsCommand: {
            specialTerms: e.detail.specialTerms,
          },
        });
      }
    }
  }

  async onUpdateSubscription(e: CustomEvent<Subscription>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const message: OrganizationSettingsUpdateMessage = {
          invoiceAddress: e.detail.invoiceAddress,
          reference: e.detail.invoiceReference,
          invoiceReceiver: e.detail.invoiceReceiver,
          invoiceReference: e.detail.invoiceReference,
          ownerEmail: e.detail.ownerEmail,
          invoicePostcode: e.detail.invoicePostcode,
          invoiceLocality: e.detail.invoiceLocality,
          specialTerms: e.detail.specialTerms as OrganizationSettingsUpdateMessageSpecialTermsEnum,
          invoiceSendMethod: e.detail.invoiceSendMethod as OrganizationSettingsUpdateMessageInvoiceSendMethodEnum,
          singleUser: e.detail.singleUser,
          invoiceOrganizationNumber: e.detail.invoiceOrganizationNumber,
        };
        await updateSubscription(state.token ?? '', o.organizationId, message);
      }
    }
  }

  onCreateIssue(e: CustomEvent<{ id: string; message: IssueUpdateMessage }>) {
    getStore().dispatch(saveItem('issues', e.detail.id, e.detail.message));
  }

  async onRestore(e: CustomEvent<{ entityType: string; entityId: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await restore(this.appState.user.token, o.organizationId, {
          entityType: e.detail.entityType,
          entityId: e.detail.entityId,
        });
      }
    }
  }

  async onChecklistChanged(e: CustomEvent<{ content: string; uuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.eventOccurrences.updateEventOccurrenceChecklist({
          organizationId: '' + o.organizationId,
          eventOccurrenceId: e.detail.uuid,
          updateChecklistMessage: {
            instance: '',
            content: e.detail.content,
          },
        });
      }
    }
  }

  async onRestoreRevision(
    e: CustomEvent<{ name: string; content: string; status: string; type: string; uuid: string }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await this.api.organization.restoreRevision({
          organizationId: '' + o.organizationId,
          restoreRevisionCommand: {
            entityId: e.detail.uuid,
            entityType: e.detail.type,
            name: e.detail.name,
            content: e.detail.content,
            status: e.detail.status,
          },
        });
      }
    }
  }

  async onAddTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const task = o.tasksById[e.detail.taskUuid];
        if (task !== undefined) {
          await this.api.functions.postFunctionTaskConnectAsset({
            organizationId: '' + o.organizationId,
            functionId: task.functionUuid,
            taskId: task.uuid,
            connectAssetCommand: {
              assetUuid: e.detail.assetUuid,
            },
          });
        }
      }
    }
  }

  async onRemoveTask(e: CustomEvent<{ taskUuid: string; assetUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const task = o.tasksById[e.detail.taskUuid];
        if (task !== undefined) {
          await this.api.functions.postFunctionTaskDisconnectAsset({
            organizationId: '' + o.organizationId,
            functionId: task.functionUuid,
            taskId: task.uuid,
            disconnectAssetCommand: {
              assetUuid: e.detail.assetUuid,
            },
          });
        }
      }
    }
  }

  async onSaveDraft(
    e: CustomEvent<{ entityType: string; entityUuid: string; draft: Record<string, unknown>; done: () => void }>,
  ) {
    await this.doSaveDraft(e);
    e.detail.done();
  }

  async onClearDraft(e: CustomEvent<{ entityType: string; entityUuid: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters = {
          organizationId: '' + o.organizationId,
          clearDraftCommand: {
            entityType: e.detail.entityType,
            entityUuid: e.detail.entityUuid,
          },
        };
        await this.api.organization.clearDraft(requestParameters);
      }
    }
  }

  async onCreateEntity(e: CustomEvent<CreateEntityInput>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      this.edit = true;
      this.dispatchEvent(
        new CustomEvent<{ href: string }>('navigate', {
          bubbles: true,
          composed: true,
          detail: { href: e.detail.targetUrl },
        }),
      );
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        try {
          const p = newItemLabels.find((x) => x.type === e.detail.entityType);
          if (p) {
            this.slideNotification({ primaryText: 'Oppretter ' + p.name.toLowerCase() });
          }
          await handleCreateEntity(o.organizationId, e.detail, this.api, getStore().dispatch);
          window.history.pushState({}, '', e.detail.targetUrl);
          await handleNavigation(window.location);
          if (p) {
            this.showSlideNotification = false;
          }
        } catch (ex) {
          console.error(ex);
        }
      }
    }
  }

  async onCreateEmployee(e: CustomEvent<CreateEmployeeInput>) {
    console.log('onCreateEmployee');
    const editItem: EmployeeViewEditItem = {
      firstName: e.detail.data.firstName,
      lastName: e.detail.data.lastName,
      email: e.detail.data.email,
      accessLevel: e.detail.data.accessLevel,
      phone: e.detail.data.phone,
      expertise: '',
      profession: '',
      associationType: '',
      hprNumber: '',
      herNumber: '',
      status: 'ACTIVE',
      nextOfKin: '',
      secondaryPhone: '',
      address: '',
      gender: 'UNDEFINED',
      notes: '',
      functionAssignments: [],
    };
    const message = updateMessage(getStore().getState(), { type: 'employees', uuid: e.detail.data.uuid, editItem });
    await getStore().dispatch(saveItem('employees', e.detail.data.uuid, message));
    await getStore().dispatch(saveItem('employees', e.detail.data.uuid, message));
    e.detail.complete();
  }

  async createPartnerForContact(uuid: string, name: string) {
    const editItem: PartnerViewEditItem = {
      address: '',
      email: '',
      industry: '',
      notes: '',
      pages: [],
      phone: '',
      physicalAccess: false,
      remoteAccess: false,
      url: '',
      name,
    };
    const partnerMessage = updateMessage(getStore().getState(), {
      type: 'partners',
      uuid,
      editItem,
    });
    await getStore().dispatch(saveItem('partners', uuid, partnerMessage));
    await getStore().dispatch(saveItem('partners', uuid, partnerMessage));
  }

  async onCreateContactAndPartner(e: CustomEvent<CreateContactAndPartnerInput>) {
    if (e.detail.data.newPartnerName) {
      await this.createPartnerForContact(e.detail.data.partnerUuid, e.detail.data.newPartnerName);
    }
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        try {
          const input: CreateContactInput = {
            entityType: 'contacts',
            entityUuid: e.detail.data.contactUuid,
            partnerId: e.detail.data.partnerUuid,
            targetUrl: '',
          };
          await handleCreateEntity(o.organizationId, input, this.api, getStore().dispatch);
          const editItem: ContactViewEditItem = {
            notes: '',
            email: e.detail.data.email,
            lastName: e.detail.data.lastName,
            firstName: e.detail.data.firstName,
            name: '',
            telephone: '',
            mobilePhone: e.detail.data.phone,
            accessLevel: 'NONE',
            accessExpires: '',
            partnerId: e.detail.data.partnerUuid,
          };
          const contactMessage = updateMessage(getStore().getState(), {
            type: 'contacts',
            uuid: e.detail.data.contactUuid,
            editItem,
          });
          await getStore().dispatch(saveItem('contacts', e.detail.data.contactUuid, contactMessage));
          e.detail.complete();
        } catch (ex) {
          console.error(ex);
        }
      }
    }
  }

  async onDataItemChanged(e: CustomEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        await dataItemChanged(o, e, state, (s: string) => this.slideNotification({ primaryText: s }));
      }
    }
  }

  async onComputerNetworkChange(e: CustomEvent<ComputerNetworkChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await computerNetworkChangeHandler(e);
    }
  }

  async onDataItemsRemoved(e: CustomEvent<{ uuid: string; dataType: string; category: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await dataItemsRemovedHandler(e);
    }
  }

  onLinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    if (this.appState && this.appState.page === 'account') {
      const message = {
        redirect: window.location.origin,
        provider: e.detail.provider,
      };
      dabihStore
        .linkAuth(this.appState.user.token, message)
        .then((result) => {
          window.location.href = result.url ?? '';
        })
        .catch((err) => {
          console.error(err);
          displayAlert('Lagring feilet. Vennligst prøv igjen');
        });
    }
  }

  onUnlinkAuth(e: CustomEvent<{ provider: 'oidc' | 'criipto' }>) {
    if (this.appState && this.appState.page === 'account') {
      const message = {
        authDescription:
          e.detail.provider === 'oidc'
            ? this.appState.user.authDescription ?? ''
            : this.appState.user.criiptoAuthDescription ?? '',
        provider: e.detail.provider,
      };
      dabihStore
        .unlinkAuth(this.appState.user.token, message)
        .then(() => {
          this.onRequestLogout(new CustomEvent('request-logout', { bubbles: true, composed: true }));
        })
        .catch((err) => {
          console.error(err);
          displayAlert('Kan ikke koble fra. Du må være logget inn med BankID for å koble den fra.');
        });
    }
  }

  async onTutorialStateChanged(e: CustomEvent<TutorialState>) {
    if (
      this.appState !== undefined &&
      this.appState.page === 'account' &&
      this.appState.viewModel?.loaded === true &&
      this.appState.viewModel.currentEmployeeUuid !== undefined
    ) {
      const p2: UpdateTutorialStateForEmployeeRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        entityId: this.appState.viewModel.currentEmployeeUuid,
        updateTutorialStateCommand: {
          id: Number.parseInt(e.detail.tutorialId),
          tutorialState: {
            currentPart: e.detail.currentPart,
            partStates: e.detail.partStates,
          },
        },
      };
      await this.api.employees.updateTutorialStateForEmployee(p2);
    }
  }

  async onDisconnectSecureLogin() {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await updateSecureLogin(this.appState.user.token, this.appState.viewModel.currentOrganizationId, {
        requiresSecureLogin: false,
      });
      window.location.href = '/';
    }
  }

  async onConnectSecureLogin() {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await updateSecureLogin(this.appState.user.token, this.appState.viewModel.currentOrganizationId, {
        requiresSecureLogin: true,
      });
      window.location.href = '/';
    }
  }

  async onMapElementChanged(e: CustomEvent<MapElementChangedDetail>) {
    await mapElementChangedHandler(e, (s: string) => this.slideNotification({ primaryText: s }));
  }

  async onMapElementDeleted(e: CustomEvent<{ uuid: string; unitType: string }>) {
    await mapElementDeletedHandler(e);
  }

  async onNewPartner(e: CustomEvent<{ uuid: string; name: string }>) {
    await getStore().dispatch(createPartner(e.detail.uuid, e.detail.name));
    console.log('onNewPartner', e.detail);
    this.slideNotification({ primaryText: (e.detail.name ?? '') + ' ble lagt til som samarbeidspartner' });
  }

  async onNewRiskAssessment(e: CustomEvent<{ uuid: string; name: string }>) {
    await getStore().dispatch(createRiskAssessment(e.detail.uuid, e.detail.name));
  }

  async onNewEmployee(e: CustomEvent<{ uuid: string; name: string }>) {
    await getStore().dispatch(createEmployee(e.detail.uuid, e.detail.name));
  }

  async onNewContract(e: CustomEvent<{ uuid: string; name: string }>) {
    await getStore().dispatch(createContract(e.detail.uuid, e.detail.name));
  }

  async onNewAsset(e: CustomEvent<{ uuid: string; name: string }>) {
    await getStore().dispatch(createAsset(e.detail.uuid, e.detail.name, ''));
  }

  async onSendFeedback(e: CustomEvent<{ url: string; message: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await sendFeedback(this.appState.user.token, {
        email: this.appState.user.username,
        message: e.detail.message,
        url: e.detail.url,
      });
    }
  }

  _zeroPad(n) {
    return String(n).padStart(2, '0');
  }

  _nowWithSeconds() {
    const date = new Date();
    const year = date.getFullYear();
    const month = this._zeroPad(date.getMonth() + 1);
    const day = this._zeroPad(date.getDate());
    const hours = this._zeroPad(date.getHours());
    const minutes = this._zeroPad(date.getMinutes());
    const seconds = this._zeroPad(date.getSeconds());
    return [year, month, day].join('-') + ' ' + [hours, minutes, seconds].join(':');
  }

  toSingleInstanceRecurrenceRule(rrule: string, instance: string): RecurrenceRule {
    const r = RecurrenceRule.fromString(rrule);
    const d = LocalDate.fromString(instance);
    return r.asSingleInstance(d);
  }

  async onCreateMeetingReport(e: CustomEvent<{ uuid: string; href: string }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const reportUuid = uuid();

      const p2: CreateMeetingOccurrenceReportRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        createMeetingOccurrenceReportCommand: {
          uuid: e.detail.uuid,
          reportUuid: reportUuid,
        },
      };
      await this.api.meetingOccurrences.createMeetingOccurrenceReport(p2);

      const s = this.currentPathArray.join('/');
      const targetUrl = s + '/reports/' + reportUuid + '?edit';
      window.history.pushState({}, '', targetUrl);
      await handleNavigation(window.location);
    }
  }

  async onSendMeetingNotice(e: CustomEvent<MessageForSend>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p2: SendMeetingOccurrenceNoticeRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        sendMeetingOccurrenceNoticeCommand: {
          uuid: e.detail.occurrenceUuid,
          message: e.detail.message,
          includeAgenda: e.detail.includeContent,
        },
      };
      await this.api.meetingOccurrences.sendMeetingOccurrenceNotice(p2);
    }
  }

  async onSendMeetingReport(e: CustomEvent<MessageForSend>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const p2: SendMeetingOccurrenceReportRequest = {
        organizationId: '' + this.appState.viewModel.currentOrganizationId,
        sendMeetingOccurrenceReportCommand: {
          uuid: e.detail.occurrenceUuid,
          message: e.detail.message,
          includeReport: e.detail.includeContent,
        },
      };
      await this.api.meetingOccurrences.sendMeetingOccurrenceReport(p2);
    }
  }

  async onUpdateUserAccess(e: UpdateUserAccessEvent) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.userType === 'EMPLOYEE') {
        await updateEmployeeAccess(
          this.appState.user.token,
          this.appState.viewModel.currentOrganizationId,
          e.detail.userUuid,
          { accessLevel: e.detail.accessLevel, email: e.detail.email },
        );
      } else {
        const o = getStore().getState().organization;
        if (o === undefined) {
          throw new Error('Illegal state (E435)');
        }
        const c = o.contactsById[e.detail.userUuid];
        if (c === undefined) {
          throw new Error('Illegal state (E435)');
        }
        await this.api.partners.postUpdateAccessForContact({
          partnerId: c.partnerUuid,
          entityId: e.detail.userUuid,
          organizationId: '' + this.appState.viewModel.currentOrganizationId,
          updateAccessCommand: {
            accessLevel: e.detail.accessLevel,
            email: e.detail.email,
            accessExpires: e.detail.accessExpires,
          },
        });
      }
    }
  }

  async onEmployeeGender(e: CustomEvent<{ uuid: string; gender: EmployeeViewModelGenderEnum }>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await updateEmployeeGender(
        this.appState.user.token,
        this.appState.viewModel.currentOrganizationId,
        e.detail.uuid,
        e.detail.gender,
      );
    }
  }

  async onTaskDone(e: CustomEvent<{ taskUuid: string }>) {
    await getStore().dispatch(taskDone(e.detail.taskUuid));
  }

  async onExternalConnectionChange(e: CustomEvent<NetworkExternalConnectionChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await externalConnectChangeHandler(e);
    }
  }

  /**
   * For dev purposes
   */
  getRelativeUrl(url: string): string {
    const urlObj = new URL(url);
    return window.location.origin + urlObj.pathname + urlObj.search;
  }

  async onCheckEmail(e: CustomEvent<{ email: string; callback: (exists: boolean) => void }>) {
    const r = await checkEmail(e.detail.email);
    console.log('onCheckEmail', r);
    e.detail.callback(r.message === 'exists');
  }

  async onCreateUser(e: CustomEvent<{ email: string; module: string; serviceType: string }>) {
    console.log('creating user account for ' + e.detail.email + ' with ' + e.detail.module);

    const r = await createUser({
      createUserCommand: {
        email: e.detail.email,
        module: e.detail.module,
        serviceType: e.detail.serviceType,
      },
    });
    const target = this.getRelativeUrl(r.target);
    console.log('received response ' + target);
    setTimeout(() => {
      window.location.href = target;
    }, 400);
  }

  async onCreateOrganization(e: CustomEvent<NewOrganization>) {
    console.log('creating account for ' + e.detail.number + ' with ' + e.detail.plan + '/' + e.detail.module);
    const accounts = this.api.accounts;
    const r = await accounts.createOrganization({
      createOrganizationInput: {
        number: e.detail.number,
        name: e.detail.name,
        ownerEmail: e.detail.ownerEmail,
        addons: e.detail.addons,
        ownerFirstName: e.detail.ownerFirstName,
        ownerLastName: e.detail.ownerLastName,
        address: e.detail.address,
        postcode: e.detail.postcode,
        locality: e.detail.locality,
        module: e.detail.module.toUpperCase(),
        plan: e.detail.plan,
        employeesCount: e.detail.employeesCount,
        specialTerms: e.detail.specialTerms,
        sector: e.detail.sector,
      },
    });
    const target = this.getRelativeUrl(r.target);
    console.log('received response ' + target);
    setTimeout(() => {
      window.location.href = target;
    }, 400);
  }

  async onAccessChange(e: CustomEvent<AccessChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await accessChangeHandler(e);
    }
  }

  async onSaveWorkday(
    e: CustomEvent<{
      value: EditException;
      employeeUuid: string;
      sameAsScheduled: boolean;
      day: string;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      if (e.detail.sameAsScheduled) {
        await deleteEmployeeWorkScheduleException(
          this.appState.user.token,
          this.appState.viewModel.currentOrganizationId,
          e.detail.employeeUuid,
          e.detail.day,
        );
      } else {
        const exception: WorkScheduleException = {
          date: e.detail.day,
          work: e.detail.value.type === 'work',
          start: e.detail.value.start,
          end: e.detail.value.end,
        };
        await updateEmployeeWorkScheduleException(
          this.appState.user.token,
          this.appState.viewModel.currentOrganizationId,
          e.detail.employeeUuid,
          exception,
        );
      }
    }
  }

  async onBackupChange(e: CustomEvent<BackupChange>) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      await backupChangeHandler(e);
    }
  }

  onRenameAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      getStore().dispatch(
        renameAttachment(
          e.detail.document.entityType,
          e.detail.document.uuid,
          e.detail.document.attachmentId,
          e.detail.document.name,
        ),
      );
    }
  }

  onDeleteAttachment(
    e: CustomEvent<{
      document: FileViewerDocument;
    }>,
  ) {
    if (this.appState !== undefined && this.appState.page === 'account' && this.appState.viewModel?.loaded === true) {
      getStore().dispatch(
        deleteAttachment(e.detail.document.entityType, e.detail.document.uuid, e.detail.document.attachmentId),
      );
    }
  }

  async onRequestSignatures(e: CustomEvent<{ entityType: string; entityUuid: string; employees: string[] }>) {
    const o = getStore().getState().organization;
    if (o !== undefined)
      await this.api.organization.requestSignatures({
        organizationId: '' + o.organizationId,
        requestSignaturesCommand: {
          message: 'Vennligst signer',
          employees: e.detail.employees,
          entityType: e.detail.entityType,
          entityUuid: e.detail.entityUuid,
        },
      });
  }

  private async doSaveDraft(
    e: CustomEvent<{
      entityType: string;
      entityUuid: string;
      draft: Record<string, unknown>;
      done: () => void;
    }>,
  ) {
    if (this.appState && this.appState.page === 'account' && this.appState.viewModel?.loaded) {
      const state = getStore().getState();
      const o = getOrganization(state);
      if (o !== undefined) {
        const requestParameters = {
          organizationId: '' + o.organizationId,
          saveDraftCommand: {
            entityType: e.detail.entityType === 'contacts' ? 'contactPersons' : e.detail.entityType,
            entityUuid: e.detail.entityUuid,
            draft: e.detail.draft,
          },
        };
        if (!isEqual(this.lastDraftSaved, requestParameters)) {
          this.lastDraftSaved = requestParameters;
          await this.api.organization.saveDraft(requestParameters);
        }
      }
    }
  }

  private async updateAppState(state: State) {
    this.currentPathArray = currentPathArray(state);
    const page = this.currentPathArray[1] ?? '';
    const usernameFromLocalStorage = localStorage.getItem('dabih-username');
    console.log('User: ' + usernameFromLocalStorage + ' at page ' + page);
    if (usernameFromLocalStorage !== null) {
      console.log('has user ' + usernameFromLocalStorage);
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else if (page === 'link') {
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else if (page === 'signup') {
      this.appState = await this.appStateForPage(page, state);
      console.log(this.appState);
    } else {
      if (page !== 'signin') {
        this.dispatchEvent(
          new CustomEvent('navigate', {
            composed: true,
            bubbles: true,
            detail: { href: '/signin' },
          }),
        );
      }
      this.appState = {
        page: 'signin',
      };
    }
  }

  private async appStateForPage(page: string, state: State): Promise<AppState> {
    switch (page) {
      case 'link':
        return this.appStateForLink();
      case 'account':
        return this.appStateForAccount(state);
      case 'p':
        return this.appStateForPageRedirect();
      case 'a':
        return this.appStateForEntityRedirect();
      case '':
        return this.appStateForIndex(state);
      case 'signin':
        return this.appStateForSignin();
      case 'signup':
        return this.appStateForSignup(state);
      default:
        return this.appStatePageNotFound(page);
    }
  }

  private startListeningToChannels(
    ablyClient: Types.RealtimePromise,
    organizationId: number,
    token: string,
    suffix: string,
  ) {
    const hex = '214c9a797bd5f4ae5a82ec14e28445863829fe8636fab137e1c9c4b779f30257';
    const regExpMatchArray = hex.match(/[\da-f]{2}/gi);
    if (regExpMatchArray === null) {
      throw new Error('Illegal state (E27), cipher params wrong format');
    }
    const numbers = regExpMatchArray.map((h) => parseInt(h, 16));

    const typedArrayKey = new Uint8Array(numbers);

    const cipherParams = {
      key: typedArrayKey,
    };

    const channelOpts = { cipher: cipherParams };

    const channel = ablyClient.channels.get('organization' + suffix + '-' + organizationId, channelOpts);
    channel.on('attached', (stateChange) => {
      console.log('channel ' + channel.name + ' is now attached');
      console.log('Message continuity on this channel ' + (stateChange.resumed ? 'was' : 'was not') + ' preserved');
      if (!stateChange.resumed) {
        // location.reload();
      }
    });

    channel.subscribe((message) => {
      const m = JSON.parse(message.data);
      console.log('message', m.path, m.eventType);

      if (!m.path.endsWith('/archive')) {
        getStore().dispatch(updateItem(m));
      }
      if (m.path === '/organizations/' + organizationId) {
        fetchPageGroups(organizationId, token).then((pages) => getStore().dispatch(loadedPageGroups(pages)));
      }
    });

    const myListener = (stateChange) => {
      console.log('channel state ', stateChange);
      console.log('channel state is ' + stateChange.current);
      console.log('previous state was ' + stateChange.previous);
      if (stateChange.reason) {
        console.log('the reason for the state change was: ' + stateChange.reason.toString());
      }
    };
    channel.on(myListener);
  }

  private createAblyClient(lastToken: Types.TokenRequest | undefined, user: User) {
    const ablyClient = new Ably.Realtime.Promise({
      authCallback: (_params, callback) => {
        console.log('authCallback');
        if (lastToken) {
          callback('', lastToken);
          lastToken = undefined;
        } else {
          fetch(dabihStore.BASE_PATH + '/accounts/' + user.username, {
            headers: {
              Accept: 'application/json',
              'Content-Type': 'application/json',
              Authorization: 'Basic ' + user.token,
            },
            method: 'GET',
          })
            .then(function (res) {
              return res.json();
            })
            .then(function (r) {
              console.warn(r);
              callback('', JSON.parse(r.ablyTokenRequest));
            })
            .catch((res) => {
              console.log(res);
              displayAlert('Der var et problem ved tilkobling til server. Vennligst last inn siden på nytt');
              callback(res, '');
            });
        }
      },
    });

    ablyClient.connection.on('connected', (stateChange) => {
      console.log('# successful connection', stateChange);
      this.online = true;
    });

    ablyClient.connection.on('failed', (stateChange) => {
      console.log('# failed connection', stateChange);
      this.online = false;
    });
    ablyClient.connection.on('disconnected', (stateChange) => {
      console.log('# disconnected connection', stateChange, stateChange.reason);
      this.online = false;
    });
    ablyClient.connection.on('suspended', (stateChange) => {
      console.log('# suspended connection', stateChange);
      this.online = false;
    });
    return ablyClient;
  }

  /*



                 _updateDecline() {
                    this.dispatch(dabihStore.declineTaskTemplateUpdate(this.item.functionUuid, this.entityId));
                  }
                 this.dispatchAction(dabihStore.sendChangedEvent(alertDataForSend));


                  From meeting:


                   beforeEdit(editItem) {
                     editItem.functionUuid = this.item.functionUuid;
                     if (editItem.functionUuid) {
                       this.functionUuid = editItem.functionUuid;
                     } else {
                       this.functionUuid = '';
                     }
                     if (editItem.employeeParticipants) {
                       this.employeeParticipants = editItem.employeeParticipants;
                     } else {
                       this.employeeParticipants = [];
                  }
                   }


                   _save() {
                     const functionUuid = this.functionUuid === '' ? null : this.functionUuid;
                     const updateMessage = {
                       name: this.editItem.name,
                       procedures: this.editItem.procedures,
                       functionUuid: functionUuid,
                       pages: this.editItem.pages,
                       employeeParticipants: this.employeeParticipants,
                       accessControl: this.editItem.accessControl,
                       classification: this.editItem.classification,
                     };
                     const self = this;
                     const id = this.entityId === 'new' ? dabihStore.uuid() : this.entityId;

                     this.dispatchAction(dabihStore.saveItem(this.entityType, id, updateMessage)).then(function () {
                     //  self._afterSave(id);
                     //});
                   }

                   beforeSave() {
                     this._save();
                     return true;
                   }




                  _updateDecline() {
                    this.dispatchAction(dabihStore.declineTaskTemplateUpdate(this.item.functionUuid, this.entityId));
                  }

                  _taskDone(event) {
                    this.dispatchAction(dabihStore.taskDone(event.detail.functionUuid, event.detail.task.uuid));
                  }

                  _updateDeclined(event) {
                    this.dispatchAction(dabihStore.declineTemplateUpdate(event.detail.entityType, event.detail.entityId));
                  }

                  _eventDone(event) {

                    this.dispatchAction(taskDone(event.detail.event.uuid));
                  }




                   _restoreRevision(e) {
                    // this._setRequestOnFlight(true);
                    this.editItem = this.createEditItem();

                    this.editItem.name = e.detail.name;

                    if (this.entityType === 'functions' || this.entityType === 'riskAssessments') {
                      this.editItem.description = e.detail.content;
                    } else if (this.entityType === 'tasks') {
                      this.editItem.procedures = e.detail.content;
                    } else {
                      this.editItem.content = e.detail.content;
                    }

                  const self = this;
                  // this.dispatchAction(dabihStore.saveItem(this.entityType, this.entityId, this.editItem, this.parentEntityId()))
                  //  .then(function () {
                  //    self.editItem = self.createEditItem();
                  //  })
                  //  .catch(function () {
                  //    dabihAlert('Der var et problem ved gjenoppretting. Vennligst prøv igjen.');
                  //  });
                  this.entityContent._resetRevisionsMode();
                  this.entityContent._fetchRevisions();
                }



                  FROM COMPUTERS
                  _onNewRiskAssessment(e, d) {
                    this.dispatchAction(dabihStore.createRiskAssessment(d.uuid, d.name));
                  }

                  _onNewEmployee(e, d) {
                    this.dispatchAction(dabihStore.createEmployee(d.uuid, d.name));
                  }

                _onNewContract(e, d) {
                    this.dispatchAction(dabihStore.createContract(d.uuid, d.name));
                  }

                  _onNewPartner(e, d) {
                    this.dispatchAction(dabihStore.createPartner(d.uuid, d.name));
                  }

                   _onNewAsset(e, d) {
                    this.dispatchAction(dabihStore.createAsset(d.uuid, d.name));
                  }

                 FOR ORGANIZATION
                   _done() {
                    this.dispatchAction(dabihStore.updateOrganization(this.editItem));
                    this.editModeOff();
                  }


                FROM EDIT PERIODS
                   if (this.type === 'plusTime') {
                          this.dispatchAction(dabihStore.updateEmployeePlusTimePeriods(this.employee, periods));
                        } else {
                          this.dispatchAction(dabihStore.updateEmployeeLeavePeriods(this.employee, periods));
                        }

                FROM TIME KEEPING CORRECTIONS
                   this.dispatchAction(dabihStore.updateEmployeeTimekeepingCorrections(this.employee, corrections));


                 FROM SEARCH
                 _setStartTask(e) {
                    if (e.target.classList.contains('StartTask')) {
                      this.dispatchEvent(new CustomEvent('open-start-task', { bubbles: true, composed: true }));
                      this.dispatchAction(dabihStore.updateSelectedStartTask(e.target.dataset.id));
                    }
                  }

                FROM ROBOT

                _ignoreRobot() {
                    const e = LocalDate.now().plusDays(1);
                    this.dispatchAction(
                    //  dabihStore.updateUserProperties({
                    //    ignoreRobotUntil: e.toString(),
                    //  }),
                    //);
                  }

                  _ignoreThisRobotAlert() {
                    if (this.currentRobotAlert) {
                      const clone = this.ignoredRobotAlerts.slice(0);
                      clone.push(this.currentRobotAlert.id);
                      this.dispatchAction(
                        dabihStore.updateUserPropertiesForOrganization({ ignoredRobotAlerts: clone }, this.organizationId),
                      );
                    }
                  }
                 _actionClick(e) {
                    const action = e.model.action;
                    if (action.href === '') {
                      e.preventDefault();
                    }
                    if (action.action) {
                      if (action.action.type === 'ASSIGN_FUNCTION') {
                        const f = this.functionsByTemplateId[action.action.payload.templateId];
                        this.dispatchAction(dabihStore.updateFunctionAssignEmployee(f.uuid, action.action.payload.employeeId));
                      }
                    }
                  }

                  FROM Start tasks

                     this.dispatchAction(dabihStore.updateSelectedStartTask(''));
                     this.dispatchAction(dabihStore.taskDone(this.task.functionUuid, this.task.uuid));

                   */

  private checkForPageRedirect() {
    const t = sessionStorage.getItem('targetPageId');
    if (t !== null) {
      const n = t;
      sessionStorage.removeItem('targetPageId');
      this.onOpenPage(
        new CustomEvent('open-page', {
          composed: true,
          bubbles: true,
          detail: { pageId: Number(n), queryString: '' },
        }),
      );
    } else if (sessionStorage.getItem('targetUrl') !== null) {
      const targetUrl = sessionStorage.getItem('targetUrl');
      sessionStorage.removeItem('targetUrl');
      this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: targetUrl } }));
    }
  }

  private appStateForSignin(): AppStateSignin | AppStateLoading {
    console.log('appStateForSignin');
    if (localStorage.getItem('dabih-username')) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          composed: true,
          bubbles: true,
          detail: { href: '/account' },
        }),
      );
      return { page: 'loading' };
    }
    return { page: 'signin' };
  }

  private async appStateForSignup(state: State): Promise<AppStateSignup> {
    const user: User | undefined = state.user;
    let userForSignup: UserForSignup | undefined = undefined;
    if (user) {
      const featuresMap = {
        MEETINGS: 'meetings',
        MINI: 'complete',
        BASIC: 'complete',
        EXTRA: 'complete',
      };
      userForSignup = {
        email: user.username,
        firstName: user['firstName'] === undefined ? '' : user['firstName'],
        lastName: user['lastName'] === undefined ? '' : user['lastName'],
        organizations: user.organizations.map((o) => {
          return {
            id: o.id,
            name: o.name,
            modules: o.features.map((e) => featuresMap[e] ?? ''),
          };
        }),
      };
    }

    return { page: 'signup', currentUser: userForSignup };
  }

  private appStateForLink(): AppStateLink {
    console.log('appStateForIndex');
    return { page: 'link', locationSearch: window.location.search };
  }

  private async appStateForAccount(
    state: State,
  ): Promise<AppStateNoUser | AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    console.log('appStateForAccount');
    const user = state.user;
    if (user === undefined) {
      return {
        page: 'noUser',
      };
    } else {
      return await this.appStateForUser(user, state);
    }
  }

  private async appStateForUser(
    user: User,
    state: State,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    const segments = this.currentPathArray;
    console.log(segments);
    if (segments.length > 2 && segments[2] !== '') {
      const id = Number(segments[2]);
      const userOrganization = user.organizations.find((o) => o.id === id);
      return await this.appStateForUserWithOrganization(userOrganization, user, state, segments, id);
    } else {
      console.log('no organization');
      return {
        page: 'account',
        user: user,
        helpViewerOpen: state.helpViewerOpen,
        helpViewerPage: state.currentHelpPage,
        writeAccess: false,
      };
    }
  }

  private async appStateForUserWithOrganization(
    userOrganization: OrganizationReference | undefined,
    user: User,
    state: State,
    segments: string[],
    id: number,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound | AppStateSecured> {
    if (userOrganization && userOrganization.requiresSecureLogin && user.provider === '') {
      return { page: 'secured', user: user, selectedUserOrganization: userOrganization };
    } else if (state.loading) {
      return { page: 'loading' };
    } else {
      return await this.appStateForOrganizationAccess(state, segments, userOrganization, user, id);
    }
  }

  private async appStateForOrganizationAccess(
    state: State,
    segments: string[],
    userOrganization: OrganizationReference | undefined,
    user: User,
    id: number,
  ): Promise<AppStateAccount | AppStateLoading | AppStateNotFound> {
    if (state.error) {
      return {
        page: 'notFound',
        requestedPage: segments[2],
      };
    } else {
      if (userOrganization !== undefined && userOrganization.pending) {
        return {
          page: 'account',
          user: user,
          helpViewerOpen: state.helpViewerOpen,
          helpViewerPage: state.currentHelpPage,
          writeAccess: writeAccess(state),
        };
      }

      const o = getStore().getState().organization;
      if (o === undefined || o.organizationId !== id) {
        console.log('Loading organization ' + id);
        // Sjekk at riktig organisasjon er lastet inn
        getStore().dispatch(loadOrganization(id, user));
        this.subscribeToAbly(user, id);
      }
      this.employeeNamesById = employeeNamesById(state);
      this.partnerNamesById = partnerNamesById(state);

      if (!state.loaded) {
        return {
          page: 'loading',
        };
      }

      this.checkForPageRedirect();

      return {
        page: 'account',
        user: user,
        helpViewerOpen: state.helpViewerOpen,
        helpViewerPage: state.currentHelpPage,
        viewModel: await applicationViewModel(state),
        writeAccess: writeAccess(state),
      };
    }
  }

  private appStatePageNotFound(page: string): AppStateNotFound {
    console.log('appStatePageNotFound');
    return { page: 'notFound', requestedPage: page };
  }

  private appStateForPageRedirect(): AppStateLoading {
    console.log('appStateForPageRedirect');
    const segments = this.currentPathArray;

    sessionStorage.setItem('targetPageId', segments[2]);

    const o = localStorage.getItem('lastOrganizationId');
    const target1 = o === null ? '' : '/' + o;

    this.dispatchEvent(
      new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/account' + target1 } }),
    );

    return { page: 'loading' };
  }

  private appStateForEntityRedirect(): AppStateLoading {
    console.log('appStateForPageRedirect');
    const segments = this.currentPathArray;
    const o = segments[2];
    const target1 = o === null ? '' : '/' + o;

    this.dispatchEvent(
      new CustomEvent('navigate', {
        composed: true,
        bubbles: true,
        detail: { href: '/account' + target1 + '/20?tab=map&c=' + segments[3] },
      }),
    );

    return { page: 'loading' };
  }

  private appStateForIndex(_state: State): AppStateLoading {
    console.log('appStateForIndex');
    if (localStorage.getItem('dabih-username')) {
      this.dispatchEvent(
        new CustomEvent('navigate', {
          composed: true,
          bubbles: true,
          detail: { href: '/account' },
        }),
      );
    } else {
      this.dispatchEvent(new CustomEvent('navigate', { composed: true, bubbles: true, detail: { href: '/signin' } }));
    }
    return { page: 'loading' };
  }
}

/*

this.dispatchAction(dabihStore.updateEmployeeTimekeepingCorrections(this.employee, corrections));
 */

/*
new CustomEvent('update-employee-period',
  if (this.type === 'plusTime') {
        this.dispatchAction(dabihStore.updateEmployeePlusTimePeriods(this.employee, periods));
      } else {
        this.dispatchAction(dabihStore.updateEmployeeLeavePeriods(this.employee, periods));
      }

 */

declare global {
  interface HTMLElementTagNameMap {
    'd-app': DApp;
  }
}
