/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
/**
 *
 * @export
 * @interface EmployeeSignatory
 */
export interface EmployeeSignatory {
  /**
   *
   * @type {string}
   * @memberof EmployeeSignatory
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeSignatory
   */
  signatoryId?: string;
  /**
   *
   * @type {string}
   * @memberof EmployeeSignatory
   */
  uuid?: string;
}

/**
 * Check if a given object implements the EmployeeSignatory interface.
 */
export function instanceOfEmployeeSignatory(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function EmployeeSignatoryFromJSON(json: any): EmployeeSignatory {
  return EmployeeSignatoryFromJSONTyped(json, false);
}

export function EmployeeSignatoryFromJSONTyped(json: any, ignoreDiscriminator: boolean): EmployeeSignatory {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    signatoryId: !exists(json, 'signatoryId') ? undefined : json['signatoryId'],
    uuid: !exists(json, 'uuid') ? undefined : json['uuid'],
  };
}

export function EmployeeSignatoryToJSON(value?: EmployeeSignatory | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
    signatoryId: value.signatoryId,
    uuid: value.uuid,
  };
}
