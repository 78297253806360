import { css, html, LitElement, nothing, PropertyValues } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import './d-signup-plans';
import 'src/library/elements/d-smooth-resize';
import 'src/library/editors/elements/d-select-dropdown-rich';
import 'src/library/editors/elements/d-edit-text';
import 'src/library/editors/elements/d-checkbox';
import 'src/library/fields/d-view-info';
import 'src/library/elements/d-section';
import 'src/library/fields/d-spinner-robot';
import './d-edit-select-organization';

import { SelectDropdownOption } from 'src/library/editors/elements/d-select-dropdown';
import { isEmailValid } from 'src/store';
import { isEmptyOrInvalidString } from 'src/utilities/text';
import { AddonsEnum, Feature, sectorOptions, specialTermsOptions } from 'src/store/selectors/features';
import { sortBy } from 'lodash';
import { OrganizationForSelector } from 'src/outskirts/signup/d-edit-select-organization';
import {
  AccountPlanUpdateMessageInvoicePlanEnum,
  CreateOrganizationInputSectorEnum,
  CreateOrganizationInputSpecialTermsEnum,
} from 'src/store/api';
import { unsafeHTML } from 'lit/directives/unsafe-html.js';

export interface UserOrganizationForSignup {
  id: number;
  name: string;
  modules: string[];
}

export interface UserForSignup {
  email: string;
  firstName: string;
  lastName: string;
  organizations: UserOrganizationForSignup[];
}

export interface NewOrganization {
  name: string;
  number: string;
  address: string;
  postcode: string;
  locality: string;
  sector: CreateOrganizationInputSectorEnum;
  plan: AccountPlanUpdateMessageInvoicePlanEnum;
  employeesCount: number;
  specialTerms: CreateOrganizationInputSpecialTermsEnum;
  module: string;
  addons: AddonsEnum[];
  ownerEmail: string;
  ownerFirstName?: string;
  ownerLastName?: string;
}

/**
 *
 */
@customElement('d-signup-form')
export class DSignupForm extends LitElement {
  static readonly styles = css`
    :host {
      display: block;
      font-family: var(--mainSans), sans-serif;
    }

    :host([theme-page]) {
      background-color: var(--backgroundGray);
    }

    :host([outskirts]) {
      background-color: var(--themeColor);
    }

    .loading {
      position: absolute;
      top: 0;
      left: 0;
      bottom: 0;
      right: 0;
      background: var(--backgroundGray);
    }

    :host([outskirts]) .loading {
      background: var(--themeColor);
    }

    d-spinner-robot {
      background: var(--backgroundGray);
    }

    :host([outskirts]) d-spinner-robot {
      align-items: flex-start;
      top: 144px;
      background: var(--themeColor);
    }

    d-spinner-robot > div {
      padding-top: 12px;
      text-align: center;
      line-height: 150%;
      color: var(--text-color);
    }

    d-checkbox {
      margin-top: 6px;
    }

    d-view-info {
      margin: 8px 0;
    }

    .buttons {
      display: flex;
      flex-wrap: wrap;
      align-items: end;
      justify-content: end;
      gap: 10px;
      margin-top: 6px;
    }

    input[type='submit'],
    .button {
      display: inline-block;
      height: 34px;
      box-sizing: border-box;
      border-radius: 4px;
      box-shadow: none;
      padding: 8px 12px;
      background: var(--themeColor);
      font-family: var(--mainSans), sans-serif;
      color: white;
      cursor: pointer;
    }

    input[type='submit'] {
      border: none;
      font-size: 14px;
      letter-spacing: 2px;
      font-weight: 500;
      writing-mode: horizontal-tb;
      text-transform: uppercase;
    }

    .button {
      font-size: 15px;
      font-weight: 200;
    }

    :host([outskirts]) input[type='submit'],
    :host([outskirts]) .button {
      background-color: hsla(0, 0%, 0%, 0.2);
    }

    input[type='submit'][disabled] {
      opacity: 0.6;
      cursor: default;
    }

    @media (hover: hover) {
      input[type='submit']:not([disabled]):hover,
      .button:hover {
        background-color: var(--themeColorDarkerOne);
      }
      :host([outskirts]) input[type='submit']:not([disabled]):hover,
      :host([outskirts]) .button:hover {
        background-color: hsla(0, 0%, 0%, 0.3);
      }
    }

    a {
      border-bottom: 1px solid hsla(1, 0%, 0%, 0.4);
      padding-top: 2px;
      padding-bottom: 2px;
      color: hsla(1, 0%, 0%, 0.8);
      text-decoration: none;
    }

    :host([outskirts]) a {
      color: white;
      border-color: hsla(1, 0%, 100%, 0.4);
    }

    @media (hover: hover) {
      a:hover {
        color: var(--themeColorDarkerOne);
        border-color: var(--themeColor);
      }
      :host([outskirts]) a:hover {
        color: black;
        border-color: hsla(1, 0%, 0%, 0.4);
      }
    }

    d-edit-select-organization {
      margin: -6px 0;
    }

    .organizations {
      max-height: 200px;
      overflow: auto;
      margin-bottom: 20px;
    }

    .organizations a,
    :host([outskirts]) .organizations a {
      display: flex;
      flex-wrap: wrap;
      justify-content: space-between;
      border-top: 1px solid var(--borderColorTransparent);
      border-bottom: none;
      padding: 6px 0 5px 0;
      color: var(--linkColorGray);
      cursor: pointer;
    }

    .organizations > a > span {
      display: block;
      padding: 3px 0;
    }

    .organizations > a > span:last-child {
      flex-grow: 1;
      text-align: right;
    }

    @media (hover: hover) {
      .organizations a:hover {
        color: black;
        background-color: hsla(0, 0%, 100%, 0.5);
      }
      :host([outskirts]) .organizations a:hover {
        background-color: hsla(0, 0%, 100%, 0.1);
      }
    }

    .organizations a:last-child,
    :host([outskirts]) .organizations a:last-child {
      border-bottom: 1px solid var(--borderColorTransparent);
    }
  `;
  @property({ type: Boolean, attribute: 'theme-page', reflect: true })
  themePage = false;
  @property({ type: Boolean, attribute: 'outskirts', reflect: true })
  outskirts = false;
  @property({ type: Object })
  requestedFeatureData: Feature | undefined = undefined;
  @property({ type: Array })
  modules: SelectDropdownOption[] = [];
  @property({ type: String })
  plan: AccountPlanUpdateMessageInvoicePlanEnum = 'X';
  @property({ type: Number })
  employeesCount = 1;
  @property({ type: String })
  module = '';
  @property({ type: Array })
  addons: AddonsEnum[] = [];
  @property({ type: String })
  sector = '';
  @property({ type: String })
  specialTerms = '';
  @property({ type: String })
  email = '';
  @property({ type: Boolean })
  agreementConsent = false;
  @property({ type: Object })
  currentUser: UserForSignup | undefined = undefined;
  @property({ type: String })
  submittedEmail = '';
  @property({ type: Boolean })
  emailExists = false;
  @property({ type: String })
  firstName = '';
  @property({ type: String })
  lastName = '';
  @property({ type: String })
  organizationName = '';
  @property({ type: String })
  organizationNumber = '';
  @property({ type: Array })
  organizationAddress: string[] = [];
  @property({ type: String })
  organizationPostCode = '';
  @property({ type: String })
  organizationLocality = '';
  @property({ type: Boolean, reflect: true })
  loading = true;
  @state()
  loginSelected = false;
  @state()
  addOrganization = false;
  @state()
  spinnerText = '';

  private get userOrganization() {
    if (this.currentUser?.organizations) {
      return sortBy(this.currentUser.organizations, ['name']);
    }
    return [];
  }

  private get emailExistsMessage() {
    return `<p>Epostadressen ${this.submittedEmail} er allerede registrert som brukernavn i TrinnVis.</p>
        <p>Vil du logge inn som denne brukeren, eller velge en annen epostadresse?</p>`;
  }

  private get organizationsMessage() {
    if (this.currentUser?.organizations.length === 1) {
      return `<p>Du er allerede registrert med en virksomhet.</p>
        <p>Trykk på virksomhetsnavnet eller legg til en ny virksomhet.</p>`;
    }
    return `<p>Du er allerede registrert med flere virksomheter.</p>
        <p>Velg en av virksomhetene i listen, eller legg til en ny virksomhet.</p>`;
  }

  private get emailFormValid() {
    return isEmailValid(this.email) && this.agreementConsent;
  }

  private get isValidOrganization() {
    return !isEmptyOrInvalidString(this.organizationName) && (this.organizationNumber || this.organizationLocality);
  }

  private get organizationFormValid() {
    return (
      !isEmptyOrInvalidString(this.firstName) &&
      !isEmptyOrInvalidString(this.lastName) &&
      this.isValidOrganization &&
      this.sector !== ''
    );
  }

  private get newOrganization(): NewOrganization {
    return {
      name: this.organizationName,
      number: this.organizationNumber,
      address: this.organizationAddress.join('\n'),
      postcode: this.organizationPostCode,
      locality: this.organizationLocality,
      sector: this.sector as CreateOrganizationInputSectorEnum,
      specialTerms: (this.specialTerms === '' ? 'NONE' : this.specialTerms) as CreateOrganizationInputSpecialTermsEnum,
      plan: this.plan,
      employeesCount: this.employeesCount,
      module: this.module.toUpperCase(),
      addons: this.addons,
      ownerEmail: this.currentUser?.email ?? this.submittedEmail,
      ownerFirstName: this.firstName,
      ownerLastName: this.lastName,
    };
  }

  renderLogin() {
    return html`<d-login in-signup ?theme-page="${this.themePage}" @request-login=${this.onRequestLogin}
      ><slot slot="usernameInput" name="usernameInput"></slot> <slot slot="passwordInput" name="passwordInput"></slot
    ></d-login> `;
  }

  renderSubmitEmail() {
    return html`
      <form @submit=${(e) => this.submitEmail(e)} @keyup=${this.onEmailFormKeyup}>
        <d-section borderless topless>
          <d-edit-text
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            autofocus
            class="fullWidth"
            label="Epost"
            .value=${this.email}
            @value-changed=${(e) => {
              this.email = e.detail.value;
            }}
          ></d-edit-text>
          <d-checkbox
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            class="fullWidth"
            option-label="Jeg aksepterer"
            .checked=${this.agreementConsent}
            @checked-changed=${(e) => {
              this.agreementConsent = e.detail.checked;
            }}
          >
            <a href="https://trinnvis.no/om-trinnvis/brukeravtale/" target="_blank">Brukeravtalen</a>
          </d-checkbox>
          <div class="buttons fullWidth">
            <input type="submit" value="Bestill" ?disabled="${!this.emailFormValid}" />
          </div>
        </d-section>
      </form>
    `;
  }

  renderOrganizations() {
    return html` <d-section borderless topless>
        <d-edit-text
          outskirts
          system-content
          class="fullWidth"
          label="Epost"
          .value=${this.email}
          disabled
        ></d-edit-text>
        <d-edit-text
          ?outskirts="${this.outskirts}"
          ?theme-page="${this.themePage}"
          class="minWidth300"
          system-content
          label="Fornavn"
          .value=${this.currentUser?.firstName}
          disabled
        ></d-edit-text>
        <d-edit-text
          ?outskirts="${this.outskirts}"
          ?theme-page="${this.themePage}"
          class="minWidth300"
          system-content
          label="Etternavn"
          .value=${this.currentUser?.lastName}
          disabled
        ></d-edit-text>
        <d-view-info class="fullWidth" .content=${this.organizationsMessage}></d-view-info>
      </d-section>
      <div class="organizations fullWidth">
        ${this.userOrganization.map((o) => {
          return html`<a href="/account/${o.id}/9772">
            <span>${o.name}</span>
            <span>${this.organizationNote(o)}</span>
          </a>`;
        })}
      </div>
      <div class="buttons fullWidth">
        <div class="button" @click=${() => this.selectAddOrganization()}>Legg til en ny virksomhet</div>
      </div>`;
  }

  renderLoggedIn() {
    if (this.currentUser?.organizations.length && !this.addOrganization) {
      return this.renderOrganizations();
    }
    return this.renderSubmitOrganization();
  }

  renderSubmitOrganization() {
    return html`
      <form @submit=${(e) => this.createOrganization(e)} @keyup=${this.onOrganizationFormKeyup}>
        <d-section borderless topless>
          <d-edit-text
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            disabled
            class="fullWidth"
            label="Epost"
            .value=${this.submittedEmail}
          ></d-edit-text>
          <d-edit-text
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            autofocus
            label="Fornavn"
            .value=${this.firstName}
            ?disabled="${this.currentUser?.email === this.submittedEmail && this.currentUser?.firstName !== ''}"
            @value-changed=${(e) => {
              this.firstName = e.detail.value;
            }}
          ></d-edit-text>
          <d-edit-text
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            label="Etternavn"
            .value=${this.lastName}
            ?disabled="${this.currentUser?.email === this.submittedEmail && this.currentUser?.lastName !== ''}"
            @value-changed=${(e) => {
              this.lastName = e.detail.value;
            }}
          ></d-edit-text>
          <d-edit-select-organization
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            ?disabled="${this.loading}"
            ?autofocus="${this.currentUser?.email === this.submittedEmail && !this.firstName}"
            class="fullWidth"
            label="Navn på virksomheten"
            .value=${this.organizationName}
            @value-changed=${(e: CustomEvent<OrganizationForSelector>) => {
              e.stopPropagation();
              this.organizationName = e.detail.name;
              this.organizationNumber = e.detail.number;
              this.organizationAddress = e.detail.address;
              this.organizationPostCode = e.detail.areaCode;
              this.organizationLocality = e.detail.area;
            }}
          ></d-edit-select-organization>
          <d-select-dropdown-rich
            ?outskirts="${this.outskirts}"
            ?theme-page="${this.themePage}"
            system-content
            label="Bransje"
            class="fullWidth"
            placeholder="Velg bransje"
            .options=${sectorOptions}
            .value=${this.sector}
            @value-changed=${(e) => {
              this.sector = e.detail.value;
              this.dispatchEvent(
                new CustomEvent('sector-changed', {
                  bubbles: true,
                  composed: true,
                  detail: { value: this.sector },
                }),
              );
            }}
          ></d-select-dropdown-rich>
          ${this.plan !== 'X' && this.sector === 'HEALTH'
            ? html` <d-select-dropdown
                ?outskirts="${this.outskirts}"
                ?theme-page="${this.themePage}"
                system-content
                label="Medlemsrabatt"
                .options=${specialTermsOptions}
                .value=${this.specialTerms}
                @value-changed=${(e: CustomEvent<{ value: string }>) => {
                  this.specialTerms = e.detail.value;
                  this.dispatchEvent(
                    new CustomEvent('special-terms-changed', {
                      bubbles: true,
                      composed: true,
                      detail: { value: this.specialTerms },
                    }),
                  );
                }}
              ></d-select-dropdown>`
            : nothing}
          <div class="buttons fullWidth">
            ${(this.currentUser?.organizations?.length ?? 0) > 0
              ? html`<div class="button" style="margin-top:10px;" @click=${() => (this.addOrganization = false)}>
                  Tilbake
                </div>`
              : nothing}
            <div class="buttons fullWidth">
              <input type="submit" value="Ferdig" ?disabled="${!this.organizationFormValid}" />
            </div>
          </div>
        </d-section>
      </form>
    `;
  }

  renderEmailExists() {
    return html`
      <d-section borderless topless>
        <d-view-info .content=${this.emailExistsMessage}></d-view-info>
        <div class="buttons fullWidth">
          <div class="button" @click=${() => this.selectLoginAsSubmitted()}>Logg inn som ${this.submittedEmail}</div>
          <div class="button" @click=${() => this.selectResetEmail()}>Velg en annen epostadresse</div>
        </div>
      </d-section>
    `;
  }

  renderNotLoggedIn() {
    if (this.emailExists) {
      return this.renderEmailExists();
    }
    return this.renderSubmitOrganization();
  }

  renderEmailSubmitted() {
    if (this.currentUser) {
      return this.renderLoggedIn();
    }
    return this.renderNotLoggedIn();
  }

  renderForm() {
    if (this.loginSelected) {
      return this.renderLogin();
    }
    if (this.currentUser) {
      this.email = this.currentUser.email;
      this.submittedEmail = this.currentUser.email;
      return html` ${this.renderLoggedIn()}`;
    }
    return html`
      ${!this.submittedEmail ? html` ${this.renderSubmitEmail()} ` : html` ${this.renderEmailSubmitted()} `}
    `;
  }

  render() {
    return html` <d-smooth-resize>
      ${this.renderForm()}
      ${this.loading
        ? html`<div class="loading">
            <d-spinner-robot ?darker="${this.outskirts}"><div>${unsafeHTML(this.spinnerText)}</div></d-spinner-robot>
          </div>`
        : nothing}</d-smooth-resize
    >`;
  }

  protected firstUpdated(_changedProperties: PropertyValues) {
    super.firstUpdated(_changedProperties);
    setTimeout(() => {
      this.loading = false;
    }, 2000);
  }

  protected updated(_changedProperties: PropertyValues) {
    super.updated(_changedProperties);
    if (
      _changedProperties.has('submittedEmail') &&
      this.submittedEmail &&
      this.submittedEmail === this.currentUser?.email
    ) {
      this.firstName = this.currentUser.firstName;
      this.lastName = this.currentUser.lastName;
    }
  }

  private organizationNote(o: UserOrganizationForSignup) {
    if (this.requestedFeatureData) {
      if (o.modules.includes('complete') || o.modules.includes(this.module)) {
        return this.requestedFeatureData.displayName + ' er inkludert';
      }
      return 'Legg til ' + this.requestedFeatureData.displayName;
    }
    return '';
  }

  private onRequestLogin(e: CustomEvent<{ user: { username: string; password: string }; loginFailed: () => void }>) {
    e.preventDefault();
    e.stopPropagation();
    const params = {
      email: e.detail.user.username,
      emailExists: 'true',
      submittedEmail: e.detail.user.username,
      plan: this.plan,
      employeesCount: this.employeesCount + '',
      sector: this.sector,
      specialTerms: this.specialTerms,
      module: this.module,
    };
    const p = new URLSearchParams(params).toString();
    this.dispatchEvent(
      new CustomEvent('request-login', {
        bubbles: true,
        composed: true,
        detail: { ...e.detail, target: '/signup?' + p },
      }),
    );
  }

  private submitEmail(e) {
    e.preventDefault();
    if (this.emailFormValid) {
      this.loading = true;
      this.submittedEmail = this.email;
      this.dispatchEvent(
        new CustomEvent<{ email: string; callback: (exists: boolean) => void }>('check-email', {
          bubbles: true,
          composed: true,
          detail: {
            email: this.email,
            callback: (exists) => {
              if (exists) {
                this.emailExists = true;
              }
              this.loading = false;
            },
          },
        }),
      );
    }
  }

  private onEmailFormKeyup(event: KeyboardEvent) {
    if (event.key === 'Enter') {
      event.preventDefault();
      this.submitEmail(event);
    }
  }

  private selectAddOrganization() {
    this.addOrganization = true;
    this.firstName = this.currentUser?.firstName ?? '';
    this.lastName = this.currentUser?.lastName ?? '';
  }

  private selectLoginAsSubmitted() {
    this.loginSelected = true;
  }

  private selectResetEmail() {
    this.email = '';
    this.submittedEmail = '';
    this.emailExists = false;
  }

  private createOrganization(e) {
    e.preventDefault();
    this.spinnerText = `Oppretter konto…<br>(tar en liten stund)`;
    this.loading = true;
    this.dispatchEvent(
      new CustomEvent<NewOrganization>('create-organization', {
        bubbles: true,
        composed: true,
        detail: this.newOrganization,
      }),
    );
  }

  private onOrganizationFormKeyup(e) {
    if (e.key === 'Enter') {
      e.preventDefault();
      this.createOrganization(e);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-signup-form': DSignupForm;
  }
}
