import { css, html, LitElement, nothing, PropertyValueMap } from 'lit';
import { customElement, property, state } from 'lit/decorators.js';
import '../../library/fields/d-expansion.js';
import '../../library/elements/d-action.js';
import type { RobotAlertItem, RobotAlertRuleAction } from 'src/store';
import type { FunctionViewModel } from 'src/store/api';

/**
 *
 * Displays the current robot alert including message and actions. When a new alert is set the current
 * one is first slide down before the new one slides up.
 *
 *
 *
 *
 */
@customElement('d-robot-alert')
export class DRobotAlert extends LitElement {
  static readonly styles = css`
    :host {
      display: flex;
      position: fixed;
      bottom: 0;
      z-index: 100;
    }

    #wrapper {
      flex: none;
      display: flex;
      align-items: flex-end;
      background-image: radial-gradient(
        farthest-corner at 0 100%,
        hsla(197, 82%, 33%, 0.8) 20%,
        hsla(197, 82%, 33%, 0) 70%
      );
      margin-top: 20px;
      margin-right: 20px;
      padding-top: 20px;
      padding-left: 40px;
      max-width: 100vw;
      transition: all 0s;
    }

    @media only screen and (max-width: 600px) {
      #wrapper {
        margin-right: 20px;
        padding-left: 0;
      }
    }

    .maxWidth600 #wrapper {
      margin-right: 20px;
      padding-left: 0;
    }

    #robot {
      width: 110px;
      height: 110px;
      flex: none;
      background: url('/images/robot.png') 0 0 / 110px 110px no-repeat;
      position: relative;
    }

    #eyes {
      position: absolute;
      display: flex;
      justify-content: space-between;
      width: 35px;
      top: 18px;
      left: 39px;
      animation: blink 8s infinite both;
    }

    .eye {
      position: relative;
      flex: none;
      width: 12px;
      height: 12px;
      border-radius: 6px;
      background: white;
    }

    .pupil {
      position: absolute;
      top: 3px;
      right: 3px;
      width: 6px;
      height: 6px;
      border-radius: 3px;
      background: hsl(196, 83%, 49%);
      animation: peek 8s infinite both;
    }

    @keyframes blink {
      56.999% {
        opacity: 1;
      }
      57% {
        opacity: 0;
      }
      59.999% {
        opacity: 0;
      }
      60% {
        opacity: 1;
      }
      96.999% {
        opacity: 1;
      }
      97% {
        opacity: 0;
      }
      99.999% {
        opacity: 0;
      }
    }

    @keyframes peek {
      59.999% {
        right: 3px;
      }
      60% {
        right: 1px;
      }
      99.999% {
        right: 1px;
      }
    }

    #callout {
      box-sizing: border-box;
      max-width: 720px;
      margin-bottom: 20px;
      padding: 18px 20px;
      border-radius: 12px;
      background: white;
      display: inline-block;
      position: relative;
      box-shadow: -4px 4px 30px 6px hsla(197, 82%, 33%, 0.6);
    }

    #callout:before {
      content: ' ';
      width: 0;
      height: 0;
      border-top: 7px solid transparent;
      border-right: 20px solid white;
      border-bottom: 7px solid transparent;
      display: inline-block;
      position: absolute;
      bottom: 40px;
      left: -20px;
    }

    .message {
      line-height: 130%;
      margin-bottom: 6px;
      max-height: 500px;
      overflow: auto;
    }

    .actions {
      text-align: right;
      margin-top: 8px;
    }

    .action.mini {
      display: inline-block;
      padding-left: 12px;
    }
  `;
  /**
   * The current robot alert to show. Set as undefined to hide the robot
   */
  @property({ type: Object })
  currentRobotAlert?: RobotAlertItem;
  @state()
  internalUp = false;
  @state()
  internalRobotAlert?: RobotAlertItem;
  @property({ type: String })
  usernameAsPropertySuffix = '';
  @property({ type: Array })
  ignoredRobotAlerts: any[] = [];
  @property({ type: Number })
  organizationId: number | null = 0;
  @property({ type: Object })
  functionsByTemplateId: Map<number, FunctionViewModel> = new Map<number, FunctionViewModel>();

  onIgnoreRobot() {
    this.dispatchEvent(new CustomEvent('ignore-robot', { composed: true, bubbles: true, detail: {} }));
  }

  onIgnoreThisRobotAlert() {
    if (this.currentRobotAlert) {
      this.dispatchEvent(
        new CustomEvent('ignore-this-robot-alert', {
          composed: true,
          bubbles: true,
          detail: { id: this.currentRobotAlert.id },
        }),
      );
    }
  }

  _currentRobotAlertChanged(newAlert: RobotAlertItem | undefined) {
    if (this.internalRobotAlert === undefined || newAlert === undefined) {
      this.internalRobotAlert = newAlert;
      this.internalUp = newAlert !== undefined;
    } else if (newAlert.id !== this.internalRobotAlert.id) {
      this.internalUp = false;
      setTimeout(() => {
        this.internalRobotAlert = newAlert;
        this.internalUp = true;
      }, 1000);
    } else {
      this.internalRobotAlert = newAlert;
    }
  }

  _actionClick(e: CustomEvent, action: RobotAlertRuleAction) {
    if (action.href === '') {
      e.preventDefault();
    }
    if ('action' in action && action.action.type === 'ASSIGN_FUNCTION') {
      const f = this.functionsByTemplateId.get(action.action.payload.templateId);
      if (f !== undefined) {
        this.dispatchEvent(
          new CustomEvent<{ functionUuid: string; employeeUuid: string }>('assign-function', {
            composed: true,
            bubbles: true,
            detail: { functionUuid: f.uuid, employeeUuid: action.action.payload.employeeId },
          }),
        );
      }
    }
  }

  render() {
    return this.internalRobotAlert === undefined
      ? nothing
      : html`
          <d-expansion ?opened=${this.internalUp}>
            <div id="wrapper">
              <div id="robot">
                <div id="eyes">
                  <div class="eye">
                    <div class="pupil"></div>
                  </div>
                  <div class="eye">
                    <div class="pupil"></div>
                  </div>
                </div>
              </div>
              <div id="calloutWrapper">
                <div id="callout">
                  <div class="message">${this.internalRobotAlert.message}</div>
                  <div class="actions">
                    ${this.internalRobotAlert.actions.map(
                      (action) =>
                        html`<d-action
                          mini
                          href="${action.href}"
                          @click=${(e: CustomEvent) => this._actionClick(e, action)}
                          >${action.title}</d-action
                        >`,
                    )}
                    <d-action mini @click=${this.onIgnoreRobot}>Ikke nå</d-action>
                    <d-action mini @click=${this.onIgnoreThisRobotAlert}>Ikke vis dette igjen</d-action>
                  </div>
                </div>
              </div>
            </div>
          </d-expansion>
        `;
  }

  protected willUpdate(_changedProperties: PropertyValueMap<any> | Map<PropertyKey, unknown>): void {
    if (_changedProperties.has('currentRobotAlert')) {
      this._currentRobotAlertChanged(this.currentRobotAlert);
    }
  }
}

declare global {
  interface HTMLElementTagNameMap {
    'd-robot-alert': DRobotAlert;
  }
}
