/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { Buffer } from 'buffer/index.js';
import * as runtime from '../runtime';
import type {
  AttachmentPropertiesUpdateMessage,
  AttachmentViewModel,
  ComputerUpdateMessage,
  ComputerViewModel,
  ExternalConnectionUpdateMessage,
  ExternalConnectionViewModel,
  NetworkUpdateMessage,
  NetworkViewModel,
} from '../models';
import {
  AttachmentPropertiesUpdateMessageFromJSON,
  AttachmentPropertiesUpdateMessageToJSON,
  AttachmentViewModelFromJSON,
  AttachmentViewModelToJSON,
  ComputerUpdateMessageFromJSON,
  ComputerUpdateMessageToJSON,
  ComputerViewModelFromJSON,
  ComputerViewModelToJSON,
  ExternalConnectionUpdateMessageFromJSON,
  ExternalConnectionUpdateMessageToJSON,
  ExternalConnectionViewModelFromJSON,
  ExternalConnectionViewModelToJSON,
  NetworkUpdateMessageFromJSON,
  NetworkUpdateMessageToJSON,
  NetworkViewModelFromJSON,
  NetworkViewModelToJSON,
} from '../models';

export interface CreateOrUpdateComputerRequest {
  computerId: string;
  organizationId: string;
  requestBody: { [key: string]: object };
}

export interface CreateOrUpdateExternalConnectionRequest {
  externalConnectionId: string;
  organizationId: string;
  externalConnectionUpdateMessage: ExternalConnectionUpdateMessage;
}

export interface CreateOrUpdateNetworkRequest {
  networkId: string;
  organizationId: string;
  networkUpdateMessage: NetworkUpdateMessage;
}

export interface DeleteAttachmentForComputerRequest {
  fileNameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteAttachmentForExternalConnectionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteAttachmentForNetworkRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface DeleteComputerRequest {
  computerId: string;
  organizationId: string;
}

export interface DeleteExternalConnectionRequest {
  externalConnectionId: string;
  organizationId: string;
}

export interface DeleteNetworkRequest {
  networkId: string;
  organizationId: string;
}

export interface FetchAttachmentForComputerRequest {
  fileNameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentForExternalConnectionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentForNetworkRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForComputerRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForExternalConnectionRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchAttachmentLinkForNetworkRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
}

export interface FetchComputerRequest {
  computerId: string;
  organizationId: string;
}

export interface FetchExternalConnectionRequest {
  externalConnectionId: string;
  organizationId: string;
}

export interface FetchNetworkRequest {
  networkId: string;
  organizationId: string;
}

export interface ListAttachmentsForComputerRequest {
  entityId: string;
  organizationId: string;
}

export interface ListAttachmentsForExternalConnectionRequest {
  entityId: string;
  organizationId: string;
}

export interface ListAttachmentsForNetworkRequest {
  entityId: string;
  organizationId: string;
}

export interface ListComputersRequest {
  organizationId: string;
}

export interface ListExternalConnectionsRequest {
  organizationId: string;
}

export interface ListNetworksRequest {
  organizationId: string;
}

export interface RenameAttachmentForComputerRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface RenameAttachmentForExternalConnectionRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface RenameAttachmentForNetworkRequest {
  attachmentUuid: string;
  entityId: string;
  organizationId: string;
  attachmentPropertiesUpdateMessage: AttachmentPropertiesUpdateMessage;
}

export interface UploadAttachmentForComputerRequest {
  fileNameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

export interface UploadAttachmentForExternalConnectionRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

export interface UploadAttachmentForNetworkRequest {
  filenameOrAttachmentUuid: string;
  entityId: string;
  organizationId: string;
  contentLength?: string;
  body?: string;
}

/**
 *
 */
export class ComputersApi extends runtime.BaseAPI {
  /**
   * Creates or updates a computer.
   */
  async createOrUpdateComputerRaw(
    requestParameters: CreateOrUpdateComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.computerId === null || requestParameters.computerId === undefined) {
      throw new runtime.RequiredError(
        'computerId',
        'Required parameter requestParameters.computerId was null or undefined when calling createOrUpdateComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateComputer.',
      );
    }

    if (requestParameters.requestBody === null || requestParameters.requestBody === undefined) {
      throw new runtime.RequiredError(
        'requestBody',
        'Required parameter requestParameters.requestBody was null or undefined when calling createOrUpdateComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{computerId}`
          .replace(`{${'computerId'}}`, encodeURIComponent(String(requestParameters.computerId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: requestParameters.requestBody,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates or updates a computer.
   */
  async createOrUpdateComputer(
    requestParameters: CreateOrUpdateComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateComputerRaw(requestParameters, initOverrides);
  }

  /**
   * Update or create external connection with the specified external connection id, and the search index for the external connection will also be updated or created.
   * Creates or updates an external connection.
   */
  async createOrUpdateExternalConnectionRaw(
    requestParameters: CreateOrUpdateExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.externalConnectionId === null || requestParameters.externalConnectionId === undefined) {
      throw new runtime.RequiredError(
        'externalConnectionId',
        'Required parameter requestParameters.externalConnectionId was null or undefined when calling createOrUpdateExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateExternalConnection.',
      );
    }

    if (
      requestParameters.externalConnectionUpdateMessage === null ||
      requestParameters.externalConnectionUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'externalConnectionUpdateMessage',
        'Required parameter requestParameters.externalConnectionUpdateMessage was null or undefined when calling createOrUpdateExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{externalConnectionId}`
          .replace(`{${'externalConnectionId'}}`, encodeURIComponent(String(requestParameters.externalConnectionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: ExternalConnectionUpdateMessageToJSON(requestParameters.externalConnectionUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create external connection with the specified external connection id, and the search index for the external connection will also be updated or created.
   * Creates or updates an external connection.
   */
  async createOrUpdateExternalConnection(
    requestParameters: CreateOrUpdateExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateExternalConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Update or create network with the specified network id.
   * Creates or updates a network.
   */
  async createOrUpdateNetworkRaw(
    requestParameters: CreateOrUpdateNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.networkId === null || requestParameters.networkId === undefined) {
      throw new runtime.RequiredError(
        'networkId',
        'Required parameter requestParameters.networkId was null or undefined when calling createOrUpdateNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling createOrUpdateNetwork.',
      );
    }

    if (requestParameters.networkUpdateMessage === null || requestParameters.networkUpdateMessage === undefined) {
      throw new runtime.RequiredError(
        'networkUpdateMessage',
        'Required parameter requestParameters.networkUpdateMessage was null or undefined when calling createOrUpdateNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{networkId}`
          .replace(`{${'networkId'}}`, encodeURIComponent(String(requestParameters.networkId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: NetworkUpdateMessageToJSON(requestParameters.networkUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Update or create network with the specified network id.
   * Creates or updates a network.
   */
  async createOrUpdateNetwork(
    requestParameters: CreateOrUpdateNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.createOrUpdateNetworkRaw(requestParameters, initOverrides);
  }

  /**
   * Deletes an attachment for a computer.
   */
  async deleteAttachmentForComputerRaw(
    requestParameters: DeleteAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.fileNameOrAttachmentUuid === null ||
      requestParameters.fileNameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'fileNameOrAttachmentUuid',
        'Required parameter requestParameters.fileNameOrAttachmentUuid was null or undefined when calling deleteAttachmentForComputer.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments/{fileNameOrAttachmentUuid}`
          .replace(
            `{${'fileNameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.fileNameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes an attachment for a computer.
   */
  async deleteAttachmentForComputer(
    requestParameters: DeleteAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForComputerRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an external connection.
   */
  async deleteAttachmentForExternalConnectionRaw(
    requestParameters: DeleteAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for an external connection.
   */
  async deleteAttachmentForExternalConnection(
    requestParameters: DeleteAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForExternalConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a network.
   */
  async deleteAttachmentForNetworkRaw(
    requestParameters: DeleteAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling deleteAttachmentForNetwork.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling deleteAttachmentForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteAttachmentForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete specified attachment for specified function.
   * Deletes an attachment for a network.
   */
  async deleteAttachmentForNetwork(
    requestParameters: DeleteAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteAttachmentForNetworkRaw(requestParameters, initOverrides);
  }

  /**
   * Deletes a computer.
   */
  async deleteComputerRaw(
    requestParameters: DeleteComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.computerId === null || requestParameters.computerId === undefined) {
      throw new runtime.RequiredError(
        'computerId',
        'Required parameter requestParameters.computerId was null or undefined when calling deleteComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{computerId}`
          .replace(`{${'computerId'}}`, encodeURIComponent(String(requestParameters.computerId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Deletes a computer.
   */
  async deleteComputer(
    requestParameters: DeleteComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteComputerRaw(requestParameters, initOverrides);
  }

  /**
   * Delete external connection with the specified external connection id, the search index of the external connection will also be deleted.
   * Deletes an external connection.
   */
  async deleteExternalConnectionRaw(
    requestParameters: DeleteExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.externalConnectionId === null || requestParameters.externalConnectionId === undefined) {
      throw new runtime.RequiredError(
        'externalConnectionId',
        'Required parameter requestParameters.externalConnectionId was null or undefined when calling deleteExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{externalConnectionId}`
          .replace(`{${'externalConnectionId'}}`, encodeURIComponent(String(requestParameters.externalConnectionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete external connection with the specified external connection id, the search index of the external connection will also be deleted.
   * Deletes an external connection.
   */
  async deleteExternalConnection(
    requestParameters: DeleteExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteExternalConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Delete network with the specified network id.
   * Deletes a network.
   */
  async deleteNetworkRaw(
    requestParameters: DeleteNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.networkId === null || requestParameters.networkId === undefined) {
      throw new runtime.RequiredError(
        'networkId',
        'Required parameter requestParameters.networkId was null or undefined when calling deleteNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling deleteNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{networkId}`
          .replace(`{${'networkId'}}`, encodeURIComponent(String(requestParameters.networkId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'DELETE',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Delete network with the specified network id.
   * Deletes a network.
   */
  async deleteNetwork(
    requestParameters: DeleteNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.deleteNetworkRaw(requestParameters, initOverrides);
  }

  /**
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForComputerRaw(
    requestParameters: FetchAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.fileNameOrAttachmentUuid === null ||
      requestParameters.fileNameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'fileNameOrAttachmentUuid',
        'Required parameter requestParameters.fileNameOrAttachmentUuid was null or undefined when calling fetchAttachmentForComputer.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments/{fileNameOrAttachmentUuid}`
          .replace(
            `{${'fileNameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.fileNameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForComputer(
    requestParameters: FetchAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForComputerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForExternalConnectionRaw(
    requestParameters: FetchAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForExternalConnection(
    requestParameters: FetchAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForExternalConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForNetworkRaw(
    requestParameters: FetchAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling fetchAttachmentForNetwork.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for specified function.
   * Gets an attachment for a {organization id}.
   */
  async fetchAttachmentForNetwork(
    requestParameters: FetchAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentForNetworkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets an Accusoft viewing session for an attachment of a computer.
   */
  async fetchAttachmentLinkForComputerRaw(
    requestParameters: FetchAttachmentLinkForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForComputer.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Gets an Accusoft viewing session for an attachment of a computer.
   */
  async fetchAttachmentLinkForComputer(
    requestParameters: FetchAttachmentLinkForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForComputerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an external connection.
   */
  async fetchAttachmentLinkForExternalConnectionRaw(
    requestParameters: FetchAttachmentLinkForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForExternalConnection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of an external connection.
   */
  async fetchAttachmentLinkForExternalConnection(
    requestParameters: FetchAttachmentLinkForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForExternalConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a network.
   */
  async fetchAttachmentLinkForNetworkRaw(
    requestParameters: FetchAttachmentLinkForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<AttachmentViewModel>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling fetchAttachmentLinkForNetwork.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling fetchAttachmentLinkForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchAttachmentLinkForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments/{attachmentUuid}/session`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => AttachmentViewModelFromJSON(jsonValue));
  }

  /**
   * Download specified attachment for viewing.
   * Gets an Accusoft viewing session for an attachment of a network.
   */
  async fetchAttachmentLinkForNetwork(
    requestParameters: FetchAttachmentLinkForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<AttachmentViewModel> {
    const response = await this.fetchAttachmentLinkForNetworkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Gets a computer.
   */
  async fetchComputerRaw(
    requestParameters: FetchComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ComputerViewModel>> {
    if (requestParameters.computerId === null || requestParameters.computerId === undefined) {
      throw new runtime.RequiredError(
        'computerId',
        'Required parameter requestParameters.computerId was null or undefined when calling fetchComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{computerId}`
          .replace(`{${'computerId'}}`, encodeURIComponent(String(requestParameters.computerId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ComputerViewModelFromJSON(jsonValue));
  }

  /**
   * Gets a computer.
   */
  async fetchComputer(
    requestParameters: FetchComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ComputerViewModel> {
    const response = await this.fetchComputerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the external connection with the specified external connection id.
   * Gets an external connection.
   */
  async fetchExternalConnectionRaw(
    requestParameters: FetchExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<ExternalConnectionViewModel>> {
    if (requestParameters.externalConnectionId === null || requestParameters.externalConnectionId === undefined) {
      throw new runtime.RequiredError(
        'externalConnectionId',
        'Required parameter requestParameters.externalConnectionId was null or undefined when calling fetchExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{externalConnectionId}`
          .replace(`{${'externalConnectionId'}}`, encodeURIComponent(String(requestParameters.externalConnectionId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => ExternalConnectionViewModelFromJSON(jsonValue));
  }

  /**
   * Get the external connection with the specified external connection id.
   * Gets an external connection.
   */
  async fetchExternalConnection(
    requestParameters: FetchExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<ExternalConnectionViewModel> {
    const response = await this.fetchExternalConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get the network with the specified network id.
   * Gets a network.
   */
  async fetchNetworkRaw(
    requestParameters: FetchNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<NetworkViewModel>> {
    if (requestParameters.networkId === null || requestParameters.networkId === undefined) {
      throw new runtime.RequiredError(
        'networkId',
        'Required parameter requestParameters.networkId was null or undefined when calling fetchNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling fetchNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{networkId}`
          .replace(`{${'networkId'}}`, encodeURIComponent(String(requestParameters.networkId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => NetworkViewModelFromJSON(jsonValue));
  }

  /**
   * Get the network with the specified network id.
   * Gets a network.
   */
  async fetchNetwork(
    requestParameters: FetchNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<NetworkViewModel> {
    const response = await this.fetchNetworkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists attachments for a computer.
   */
  async listAttachmentsForComputerRaw(
    requestParameters: ListAttachmentsForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Lists attachments for a computer.
   */
  async listAttachmentsForComputer(
    requestParameters: ListAttachmentsForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForComputerRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an external connection.
   */
  async listAttachmentsForExternalConnectionRaw(
    requestParameters: ListAttachmentsForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for an external connection.
   */
  async listAttachmentsForExternalConnection(
    requestParameters: ListAttachmentsForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForExternalConnectionRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a network.
   */
  async listAttachmentsForNetworkRaw(
    requestParameters: ListAttachmentsForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<AttachmentViewModel>>> {
    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling listAttachmentsForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listAttachmentsForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments`
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(AttachmentViewModelFromJSON));
  }

  /**
   * Get all attachments for specified function.
   * Lists attachments for a network.
   */
  async listAttachmentsForNetwork(
    requestParameters: ListAttachmentsForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<AttachmentViewModel>> {
    const response = await this.listAttachmentsForNetworkRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Lists computers.
   */
  async listComputersRaw(
    requestParameters: ListComputersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ComputerViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listComputers.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ComputerViewModelFromJSON));
  }

  /**
   * Lists computers.
   */
  async listComputers(
    requestParameters: ListComputersRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ComputerViewModel>> {
    const response = await this.listComputersRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the external connections within the specified organization.
   * Lists external connections.
   */
  async listExternalConnectionsRaw(
    requestParameters: ListExternalConnectionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<ExternalConnectionViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listExternalConnections.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(ExternalConnectionViewModelFromJSON));
  }

  /**
   * Get all the external connections within the specified organization.
   * Lists external connections.
   */
  async listExternalConnections(
    requestParameters: ListExternalConnectionsRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<ExternalConnectionViewModel>> {
    const response = await this.listExternalConnectionsRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Get all the networks within the specified organization.
   * Lists networks.
   */
  async listNetworksRaw(
    requestParameters: ListNetworksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<Array<NetworkViewModel>>> {
    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling listNetworks.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks`.replace(
          `{${'organizationId'}}`,
          encodeURIComponent(String(requestParameters.organizationId)),
        ),
        method: 'GET',
        headers: headerParameters,
        query: queryParameters,
      },
      initOverrides,
    );

    return new runtime.JSONApiResponse(response, (jsonValue) => jsonValue.map(NetworkViewModelFromJSON));
  }

  /**
   * Get all the networks within the specified organization.
   * Lists networks.
   */
  async listNetworks(
    requestParameters: ListNetworksRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<Array<NetworkViewModel>> {
    const response = await this.listNetworksRaw(requestParameters, initOverrides);
    return await response.value();
  }

  /**
   * Updates properties of an attachment for a computer.
   */
  async renameAttachmentForComputerRaw(
    requestParameters: RenameAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForComputer.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForComputer.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Updates properties of an attachment for a computer.
   */
  async renameAttachmentForComputer(
    requestParameters: RenameAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForComputerRaw(requestParameters, initOverrides);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an external connection.
   */
  async renameAttachmentForExternalConnectionRaw(
    requestParameters: RenameAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForExternalConnection.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for an external connection.
   */
  async renameAttachmentForExternalConnection(
    requestParameters: RenameAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForExternalConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a network.
   */
  async renameAttachmentForNetworkRaw(
    requestParameters: RenameAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (requestParameters.attachmentUuid === null || requestParameters.attachmentUuid === undefined) {
      throw new runtime.RequiredError(
        'attachmentUuid',
        'Required parameter requestParameters.attachmentUuid was null or undefined when calling renameAttachmentForNetwork.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling renameAttachmentForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling renameAttachmentForNetwork.',
      );
    }

    if (
      requestParameters.attachmentPropertiesUpdateMessage === null ||
      requestParameters.attachmentPropertiesUpdateMessage === undefined
    ) {
      throw new runtime.RequiredError(
        'attachmentPropertiesUpdateMessage',
        'Required parameter requestParameters.attachmentPropertiesUpdateMessage was null or undefined when calling renameAttachmentForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    headerParameters['Content-Type'] = 'application/json';

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments/{attachmentUuid}/properties`
          .replace(`{${'attachmentUuid'}}`, encodeURIComponent(String(requestParameters.attachmentUuid)))
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: AttachmentPropertiesUpdateMessageToJSON(requestParameters.attachmentPropertiesUpdateMessage),
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * update attachment file name.
   * Updates properties of an attachment for a network.
   */
  async renameAttachmentForNetwork(
    requestParameters: RenameAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.renameAttachmentForNetworkRaw(requestParameters, initOverrides);
  }

  /**
   * Creates an attachment for a computer.
   */
  async uploadAttachmentForComputerRaw(
    requestParameters: UploadAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.fileNameOrAttachmentUuid === null ||
      requestParameters.fileNameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'fileNameOrAttachmentUuid',
        'Required parameter requestParameters.fileNameOrAttachmentUuid was null or undefined when calling uploadAttachmentForComputer.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForComputer.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForComputer.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/computers/{entityId}/attachments/{fileNameOrAttachmentUuid}`
          .replace(
            `{${'fileNameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.fileNameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Creates an attachment for a computer.
   */
  async uploadAttachmentForComputer(
    requestParameters: UploadAttachmentForComputerRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForComputerRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an external connection.
   */
  async uploadAttachmentForExternalConnectionRaw(
    requestParameters: UploadAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForExternalConnection.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForExternalConnection.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/externalConnections/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for an external connection.
   */
  async uploadAttachmentForExternalConnection(
    requestParameters: UploadAttachmentForExternalConnectionRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForExternalConnectionRaw(requestParameters, initOverrides);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a network.
   */
  async uploadAttachmentForNetworkRaw(
    requestParameters: UploadAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<runtime.ApiResponse<void>> {
    if (
      requestParameters.filenameOrAttachmentUuid === null ||
      requestParameters.filenameOrAttachmentUuid === undefined
    ) {
      throw new runtime.RequiredError(
        'filenameOrAttachmentUuid',
        'Required parameter requestParameters.filenameOrAttachmentUuid was null or undefined when calling uploadAttachmentForNetwork.',
      );
    }

    if (requestParameters.entityId === null || requestParameters.entityId === undefined) {
      throw new runtime.RequiredError(
        'entityId',
        'Required parameter requestParameters.entityId was null or undefined when calling uploadAttachmentForNetwork.',
      );
    }

    if (requestParameters.organizationId === null || requestParameters.organizationId === undefined) {
      throw new runtime.RequiredError(
        'organizationId',
        'Required parameter requestParameters.organizationId was null or undefined when calling uploadAttachmentForNetwork.',
      );
    }

    const queryParameters: any = {};

    const headerParameters: runtime.HTTPHeaders = {};

    if (requestParameters.contentLength !== undefined && requestParameters.contentLength !== null) {
      headerParameters['Content-Length'] = String(requestParameters.contentLength);
    }

    if (
      this.configuration &&
      (this.configuration.username !== undefined || this.configuration.password !== undefined)
    ) {
      headerParameters['Authorization'] =
        'Basic ' + Buffer.from(this.configuration.username + ':' + this.configuration.password).toString('base64');
    }
    if (this.configuration && this.configuration.accessToken) {
      const token = this.configuration.accessToken;
      const tokenString = await token('UserSecurityWithToken', []);

      if (tokenString) {
        headerParameters['Authorization'] = `Bearer ${tokenString}`;
      }
    }
    const consumes: runtime.Consume[] = [{ contentType: 'multipart/form-data' }];
    // @ts-ignore: canConsumeForm may be unused
    const canConsumeForm = runtime.canConsumeForm(consumes);

    let formParams: { append(param: string, value: any): any };
    let useForm = false;
    if (useForm) {
      formParams = new FormData();
    } else {
      formParams = new URLSearchParams();
    }

    if (requestParameters.body !== undefined) {
      formParams.append('body', requestParameters.body as any);
    }

    const response = await this.request(
      {
        path: `/organizations/{organizationId}/networks/{entityId}/attachments/{filenameOrAttachmentUuid}`
          .replace(
            `{${'filenameOrAttachmentUuid'}}`,
            encodeURIComponent(String(requestParameters.filenameOrAttachmentUuid)),
          )
          .replace(`{${'entityId'}}`, encodeURIComponent(String(requestParameters.entityId)))
          .replace(`{${'organizationId'}}`, encodeURIComponent(String(requestParameters.organizationId))),
        method: 'PUT',
        headers: headerParameters,
        query: queryParameters,
        body: formParams,
      },
      initOverrides,
    );

    return new runtime.VoidApiResponse(response);
  }

  /**
   * Upload attachments for specified function.
   * Creates an attachment for a network.
   */
  async uploadAttachmentForNetwork(
    requestParameters: UploadAttachmentForNetworkRequest,
    initOverrides?: RequestInit | runtime.InitOverrideFunction,
  ): Promise<void> {
    await this.uploadAttachmentForNetworkRaw(requestParameters, initOverrides);
  }
}
