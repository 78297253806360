/* tslint:disable */
/* eslint-disable */
/**
 * dabih-api
 * This is the REST api for the DABIH service by TrinnVis.
 *
 * The version of the OpenAPI document: 1.0.0
 * Contact: kristian@trinnvis.no
 *
 * NOTE: This class is auto generated by OpenAPI Generator (https://openapi-generator.tech).
 * https://openapi-generator.tech
 * Do not edit the class manually.
 */

import { exists, mapValues } from '../runtime';
import type { EmployeeSignatory } from './EmployeeSignatory';
import {
  EmployeeSignatoryFromJSON,
  EmployeeSignatoryFromJSONTyped,
  EmployeeSignatoryToJSON,
} from './EmployeeSignatory';

/**
 *
 * @export
 * @interface SigningOrder
 */
export interface SigningOrder {
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  status?: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  uuid?: string;
  /**
   *
   * @type {string}
   * @memberof SigningOrder
   */
  signatureOrderId?: string;
  /**
   *
   * @type {Array<EmployeeSignatory>}
   * @memberof SigningOrder
   */
  employees?: Array<EmployeeSignatory>;
}

/**
 * Check if a given object implements the SigningOrder interface.
 */
export function instanceOfSigningOrder(value: object): boolean {
  let isInstance = true;

  return isInstance;
}

export function SigningOrderFromJSON(json: any): SigningOrder {
  return SigningOrderFromJSONTyped(json, false);
}

export function SigningOrderFromJSONTyped(json: any, ignoreDiscriminator: boolean): SigningOrder {
  if (json === undefined || json === null) {
    return json;
  }
  return {
    status: !exists(json, 'status') ? undefined : json['status'],
    uuid: !exists(json, 'uuid') ? undefined : json['uuid'],
    signatureOrderId: !exists(json, 'signatureOrderId') ? undefined : json['signatureOrderId'],
    employees: !exists(json, 'employees')
      ? undefined
      : (json['employees'] as Array<any>).map(EmployeeSignatoryFromJSON),
  };
}

export function SigningOrderToJSON(value?: SigningOrder | null): any {
  if (value === undefined) {
    return undefined;
  }
  if (value === null) {
    return null;
  }
  return {
    status: value.status,
    uuid: value.uuid,
    signatureOrderId: value.signatureOrderId,
    employees: value.employees === undefined ? undefined : (value.employees as Array<any>).map(EmployeeSignatoryToJSON),
  };
}
